export type ISvgIconProps = {
  name: string;
  viewBox?: string;
  className?: string;
  style?: any;
};

interface IIcon {
  svg?: any;
  width?: string;
  height?: string;
  viewBox?: string;
  paths?:
    | {
        d: string;
        fill: string;
        fillRule?: 'nonzero' | 'evenodd' | 'inherit';
      }[]
    | undefined;
}

export const SvgIcon = (props: ISvgIconProps) => {
  const icon = icons[props.name];

  const svgParams = { ...icon };

  delete svgParams.paths;

  return icon?.svg;
};

const icons: { [key: string]: IIcon } = {
  // path do label veio da lib mdi-react
  labelFilled: {
    svg: (
      <svg xmlns='http://www.w3.org/2000/svg' height={20} width={20} viewBox='0 0 24 24'>
        <path d='M17.63,5.84C17.27,5.33 16.67,5 16,5H5A2,2 0 0,0 3,7V17A2,2 0 0,0 5,19H16C16.67,19 17.27,18.66 17.63,18.15L22,12L17.63,5.84Z' />
      </svg>
    )
  },
  openInNew: {
    svg: (
      <svg xmlns='http://www.w3.org/2000/svg' height={20} width={20} viewBox='0 0 24 24'>
        <path d='M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z' />
      </svg>
    )
  },
  telegramOutlined: {
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='560.76pt'
        height='560.76pt'
        viewBox='0 0 560.76 560.76'
        version='1.1'
      >
        <g id='surface1'>
          <path d='M 280.382813 26.996094 C 140.664063 26.996094 27 140.664063 27 280.378906 C 27 420.09375 140.664063 533.757813 280.382813 533.757813 C 420.097656 533.757813 533.761719 420.09375 533.761719 280.378906 C 533.761719 140.664063 420.097656 26.996094 280.382813 26.996094 M 280.382813 560.757813 C 205.488281 560.757813 135.078125 531.59375 82.121094 478.636719 C 29.164063 425.683594 0 355.273438 0 280.378906 C 0 205.484375 29.164063 135.074219 82.121094 82.117188 C 135.078125 29.160156 205.488281 -0.00390625 280.382813 -0.00390625 C 355.273438 -0.00390625 425.683594 29.160156 478.640625 82.117188 C 531.597656 135.074219 560.761719 205.484375 560.761719 280.378906 C 560.761719 355.273438 531.597656 425.683594 478.640625 478.636719 C 425.683594 531.59375 355.273438 560.757813 280.382813 560.757813 ' />
          <path d='M 342.847656 406.828125 C 345.398438 407.78125 347.46875 407.945313 349.015625 407.308594 C 350.78125 406.585938 352.019531 404.828125 352.542969 403.957031 L 399.265625 168.394531 C 397.140625 168.691406 394.3125 169.484375 392.464844 170.296875 C 392.335938 170.351563 392.207031 170.40625 392.074219 170.460938 L 124.410156 275.460938 C 124.265625 275.515625 124.121094 275.566406 123.972656 275.617188 C 122.722656 276.066406 121.207031 276.871094 120.152344 277.609375 C 121.058594 278.398438 122.511719 279.300781 123.765625 279.789063 L 190.925781 302.398438 C 191.339844 302.539063 191.746094 302.707031 192.144531 302.898438 L 232.144531 322.230469 C 232.785156 322.539063 233.394531 322.914063 233.964844 323.351563 Z M 346.945313 428.679688 C 343.050781 428.679688 338.496094 427.875 333.375 425.640625 C 332.597656 425.300781 331.859375 424.867188 331.1875 424.351563 L 222.039063 340.671875 L 183.597656 322.09375 L 116.890625 299.636719 C 116.839844 299.617188 116.785156 299.597656 116.730469 299.578125 C 115.046875 298.980469 100.242188 293.34375 98.488281 279.320313 C 96.980469 267.261719 107.429688 259.160156 116.9375 255.832031 L 384.226563 150.980469 C 388.601563 149.085938 402.558594 143.964844 412.550781 150.488281 C 415.609375 152.488281 420.742188 157.289063 420.742188 167.019531 C 420.742188 167.703125 420.675781 168.386719 420.539063 169.0625 L 372.875 409.394531 C 372.746094 410.027344 372.5625 410.648438 372.324219 411.25 C 371.871094 412.378906 367.617188 422.378906 356.988281 426.738281 C 354.441406 427.785156 351.058594 428.679688 346.945313 428.679688 ' />
          <path d='M 269.75 364.019531 L 226.242188 403.691406 C 226.242188 404.972656 219.117188 404.972656 219.117188 403.691406 L 227.449219 330.96875 L 227.90625 329.683594 C 227.90625 329.683594 349.90625 220.019531 354.90625 215.351563 C 359.90625 210.683594 358.242188 209.683594 358.242188 209.683594 C 358.574219 204.019531 349.242188 209.683594 349.242188 209.683594 L 187.574219 312.351563 C 187.574219 312.351563 207.90625 379.019531 211.90625 391.683594 C 215.90625 404.351563 218.261719 404.121094 219.117188 404.121094 ' />
          <path d='M 199.949219 316.929688 C 203.019531 326.984375 208.589844 345.222656 213.4375 361.03125 L 217.019531 329.773438 C 217.109375 328.976563 217.292969 328.195313 217.558594 327.441406 L 218.019531 326.15625 C 218.605469 324.515625 219.589844 323.039063 220.886719 321.875 C 239.648438 305.011719 266.050781 281.273438 289.925781 259.792969 Z M 222.679688 415.152344 C 220.738281 415.152344 219.042969 414.933594 217.570313 414.542969 C 208.574219 413.589844 204.765625 403.933594 201.894531 394.84375 C 197.925781 382.273438 178.363281 318.140625 177.53125 315.414063 C 176.152344 310.898438 177.960938 306.019531 181.945313 303.488281 L 343.613281 200.820313 C 343.671875 200.78125 343.730469 200.746094 343.792969 200.710938 C 348.949219 197.578125 356.4375 194.648438 362.796875 198.480469 C 365.945313 200.375 367.988281 203.511719 368.574219 207.191406 C 368.75 207.8125 368.875 208.449219 368.945313 209.101563 C 369.585938 215.160156 365.609375 219.726563 362.074219 223.027344 C 357.410156 227.382813 255.738281 318.785156 237.539063 335.144531 L 231.90625 384.316406 L 262.675781 356.261719 C 266.960938 352.351563 273.605469 352.660156 277.511719 356.945313 C 281.417969 361.230469 281.109375 367.871094 276.824219 371.777344 L 234.199219 410.644531 C 232.113281 413.15625 228.523438 415.152344 222.679688 415.152344 ' />
          <path d='M 269.742188 374.519531 C 267.484375 374.519531 265.207031 373.792969 263.285156 372.292969 L 220.984375 339.242188 C 216.414063 335.671875 215.605469 329.070313 219.175781 324.503906 C 222.746094 319.933594 229.347656 319.125 233.914063 322.695313 L 276.214844 355.746094 C 280.785156 359.316406 281.597656 365.914063 278.023438 370.484375 C 275.953125 373.132813 272.863281 374.519531 269.742188 374.519531 ' />
        </g>
      </svg>
    )
  },
  circleFilled: {
    svg: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' />
      </svg>
    )
  },
  mapMarkerOutlined: {
    svg: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z' />
      </svg>
    )
  }
};
