import './assets/blk-loading.css';
import './assets/global.css';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-quill/dist/quill.snow.css';

import Buffer from 'buffer';

import React, { useEffect, useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material';
import FormFieldsContext from '@react-form-fields/material-ui/components/Context';
import { ConfigProvider } from 'antd';
import { muiTheme, theme } from 'assets/theme';
import ColorPicker from 'components/Shared/ColorPicker';
import Loader from 'components/Shared/Loader';
import Toast from 'components/Shared/Toast';
import fieldConfig from 'fieldConfig';
import Routes from 'Routes';
import { setup } from 'rxjs-operators';
import StyledHoustonTheme from 'styles';

import GoogleMapsLib from './services/GoogleMapsLib';

const antdTheme = {
  token: {
    colorPrimary: '#FFBC00',
    colorSecondary: '#0D2772',
    colorTextLightSolid: '#000000',
    colorBgLayout: '#fcfcfc',
    colorBgTextHover: 'rgba(0, 0, 0, 0.03)',
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    borderRadius: 4
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// o react-scripts > 5.0 não tem esse Buffer então tem que colocar manualmente
window.Buffer = Buffer.Buffer;
window['LHC_CHAT_OPEN'] = false;
window['IS_CREATE_EVENT'] = false;

const App = () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const loader = React.createRef<Loader>();
  const formFieldConfig = fieldConfig;

  useEffect(() => {
    if (firstLoad) {
      setup(loader.current);
      setFirstLoad(false);
    }
  }, [firstLoad, loader]);

  console.log('(Front) Version Number: 17 / Branch: master / Commit: 90fd829137');

  return (
    <ThemeProvider theme={muiTheme}>
      <MuiThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <StyledHoustonTheme>
            <FormFieldsContext config={formFieldConfig}>
              <CssBaseline />
              <GoogleMapsLib />
              <ColorPicker />
              <Loader innerRef={loader} />
              <Toast.Global />
              <Routes />
            </FormFieldsContext>
          </StyledHoustonTheme>
        </ConfigProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default App;
