import { IEvent } from 'interfaces/event';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';
import { CDN_URL } from 'settings';

import { EventCartData } from './types';

class EventCartService {
  private getEventCartData(data: IEvent): EventCartData {
    return {
      eventHeader: {
        isLive: data.is_live,
        title: data.title,
        eventId: data.id,
        date: { start: `${data.start_date} às ${data.start_hour}`, end: `${data.end_date} às ${data.end_hour}` },
        image: CDN_URL + data?.image_path
      }
    };
  }

  public get(id: string): Rx.Observable<EventCartData> {
    return eventService.details<IEvent>(id).pipe(RxOp.map(response => this.getEventCartData(response)));
  }
}

const eventCartService = new EventCartService();
export default eventCartService;
