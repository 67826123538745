import ResendTicketDialog from 'components/Shared/ResendTicket';

import EditPresenceDialog from './EditPresenceDialog';
import LabelManagerDialog from './LabelManagerDialog';
import LinkMarkerDialog from './LinkMarkerDialog';
import ListInvites from './ListInvites';
import { IProps } from './types';

const ModalDialogs = ({
  data,
  tags,
  client,
  userEmail,
  eventId,
  eventDetail,
  eventIdSelected,
  cliCodSelected,
  selectedParticipants,
  model,
  participantsToPrint,
  pagination,
  inviteMultipleData,
  loadDataPaginate,
  isEventLiveOnline,
  isMultipleAction,
  isOpenModalLink,
  isOpenResendTicket,
  allParticipantsAreSelected,
  isOpenEdit,
  isOpenEditMultiple,
  isOpenMarkerMultiple,
  isOpenLabelManager,
  refreshData,
  handleUpdateParentState,
  isLoadingGenerateTagsModel,
  generateTagsModel,
  handleOpenModalLink,
  handleOpenModalResendTicket,
  handleOpenModalEdit,
  handleOpenModalLabelManager,
  handleUpdateAttendanceDetail,
  handleOpenAttendanceDetail,
  handleCloseAttendanceDetail,
  handleOpenModalSendEmailToParticipants,
  handleCheckinAttendances
}: IProps) => {
  const handleCloseModalEditMultiple = () => {
    handleUpdateParentState({
      isOpenEditMultiple: false,
      isOpenMarkerMultiple: false,
      isMultipleAction: false
    });
  };

  const setEditInvite = (inviteKey: string, type: string) => {
    handleCloseModalEditMultiple();
    if (type === 'edit') {
      const newModel = {
        ...model,
        id: inviteKey
      };
      handleUpdateParentState({
        model: newModel,
        isOpenEdit: true
      });
    }

    if (type === 'marker') {
      const newClient = {
        ...client,
        invite_key: inviteKey
      };
      handleUpdateParentState({
        client: newClient,
        isOpenModalLink: true
      });
    }
  };

  return (
    <>
      {isOpenModalLink && (
        <LinkMarkerDialog
          markers={tags}
          opened={isOpenModalLink}
          client={client}
          onCancel={() => handleOpenModalLink(false)}
          selectedParticipants={selectedParticipants}
          isMultipleAction={isMultipleAction}
          refreshData={refreshData}
          eventId={eventId}
          allParticipantsAreSelected={allParticipantsAreSelected}
        />
      )}

      {isOpenResendTicket && (
        <ResendTicketDialog
          opened={isOpenResendTicket}
          userEmail={userEmail}
          eventId={eventId}
          onCancel={() => handleOpenModalResendTicket(false)}
          selectedParticipants={selectedParticipants}
          isMultipleAction={isMultipleAction}
          isEventLiveOnline={isEventLiveOnline}
          allParticipantsAreSelected={allParticipantsAreSelected}
          totalOfParticipants={pagination.totalRows}
        />
      )}
      {isOpenEdit && (
        <EditPresenceDialog
          opened={isOpenEdit}
          data={model}
          dataList={data}
          eventDetail={eventDetail}
          pagination={pagination}
          loadDataPaginate={loadDataPaginate}
          handleUpdateParentState={handleUpdateParentState}
          handleUpdateAttendanceDetail={handleUpdateAttendanceDetail}
          onCancel={() => handleOpenModalEdit(false)}
        />
      )}

      {isOpenEditMultiple && (
        <ListInvites
          key='editMultipleDialog'
          opened={isOpenEditMultiple}
          data={inviteMultipleData.id}
          onComplete={setEditInvite}
          onCancel={() => handleCloseModalEditMultiple()}
          type='edit'
        />
      )}

      {isOpenMarkerMultiple && (
        <ListInvites
          key='markerMultipleDialog'
          opened={isOpenMarkerMultiple}
          data={inviteMultipleData.id}
          onComplete={setEditInvite}
          onCancel={() => handleCloseModalEditMultiple()}
          type='marker'
        />
      )}

      {isOpenLabelManager && (
        <LabelManagerDialog
          opened={isOpenLabelManager}
          onCancel={() => handleUpdateParentState({ isOpenLabelManager: false })}
          eventDetail={eventDetail}
          tags={tags}
          attendanceList={data}
          participantsToPrint={participantsToPrint}
          allParticipantsAreSelected={allParticipantsAreSelected}
          totalOfParticipants={pagination.totalRows}
          isLoadingGenerateTagsModel={isLoadingGenerateTagsModel}
          generateTagsModel={generateTagsModel}
        />
      )}
    </>
  );
};

export default ModalDialogs;
