import React, { useState, useCallback, useEffect, useMemo, Reducer, useReducer } from 'react';

import { KeyOutlined } from '@ant-design/icons';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { Alert, Col, Row, Typography } from 'antd';
import ArchiveEvent from 'components/Pages/Admin/MyEvents/ArchiveEvent';
import DuplicateEvent from 'components/Pages/Admin/MyEvents/DuplicateEvent';
import { EventHeader, EventLocal, EventDate, EventTickets } from 'components/Presentational';
import { EventDateTime } from 'components/Presentational/EventDate/types';
import { EventDate as EventDateType } from 'components/Presentational/EventHeader/types';
import { EventLocal as EventLocalType } from 'components/Presentational/EventLocal/types';
import CustomLoader from 'components/Shared/CustomLoader';
import { useCustomObservable } from 'hooks';
import { IEvent } from 'interfaces/event';
import ReactPlaceholder from 'react-placeholder/lib';
import { useHistory, useParams } from 'react-router-dom';
import eventService from 'services/event';
import { titlePageService } from 'services/routes';
import userService from 'services/user';
import { User } from 'services/user/types';
import { CDN_URL } from 'settings';

import PageTitle from '../components/PageTitle';
import FunctionalitiesModal from '../FunctionalitiesModal';
import { formatDateToPtBr } from './helpers';
import useUpdateTicketListUntilAllCntCodsAreCreated from './hooks';
import BasicDataOptionsButton from './optionsButton';
import * as S from './styles';
import { PageParams, ActionTypes, StateProps, ActionProps } from './types';

const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case ActionTypes.archiveEvent:
      return { ...state, archiveEvent: action.value as boolean };

    case ActionTypes.duplicateEvent:
      return { ...state, duplicateEvent: action.value as boolean };

    default:
      return state;
  }
};

const initialState: StateProps = {
  duplicateEvent: false,
  archiveEvent: false
};

export default function BasicData() {
  const [{ archiveEvent, duplicateEvent }, dispatch] = useReducer(reducer, initialState);

  const { value: data, loading } = useCustomObservable<IEvent, void, void>(
    () => eventService.details<IEvent>(id),
    null
  );

  const { value: userData } = useCustomObservable<User, string, string>(() => userService.getUser(), null);

  const [showDescription, setShowDescription] = useState(false);
  const history = useHistory();

  const { id } = useParams<PageParams>();

  const { eventTicketsList } = useUpdateTicketListUntilAllCntCodsAreCreated(data, id);

  useEffect(() => {
    titlePageService.set(
      ['Meus Eventos'],
      [
        { label: 'Meus Eventos', path: '/my-events' },
        { label: data?.title, path: '' },
        { label: 'Dados básicos', path: '' }
      ],
      { label: 'Meus eventos', path: '/my-events' }
    );
  }, [data]);

  const isShownData = useMemo(() => !loading && !!data, [data, loading]);

  const renderDescription = useCallback(() => {
    const handleShowDescription = () => {
      setShowDescription(true);
    };
    const text = data.description;
    const mustSliceText = text.length > 150;
    const slicedText = mustSliceText ? `${text.slice(0, 150)}...` : text;
    const description = showDescription ? text : slicedText;

    return (
      <>
        <p className='event-description-text-mobile'>
          {description}
          {!showDescription && mustSliceText && <span onClick={handleShowDescription}>Ver mais</span>}
        </p>
        <p className='event-description-text-desk'>{text}</p>
      </>
    );
  }, [showDescription, data]);

  const getShortEventDates = useCallback((data: IEvent): EventDateType => {
    return { start: `${data.start_date} às ${data.start_hour}`, end: `${data.end_date} às ${data.end_hour}` };
  }, []);

  const getEventLocation = useCallback((data: IEvent): EventLocalType => {
    if (data.is_live === 0 && data.type === 'online') {
      return { online: { url: data.stream_url } };
    }

    if (data.is_live === 1 && data.type === 'online') {
      return { live: { url: data.transmission_links } };
    }

    return {
      presential: {
        name: data.place,
        adress: `${data.street}, ${data.number} - ${data.district} - CEP: ${data.zip} - ${data.city} - ${data.country}`
      }
    };
  }, []);

  const getListEventDates = useCallback((data: IEvent): EventDateTime[] => {
    return data.event_dates?.map(date => ({
      id: date.id,
      date: formatDateToPtBr(date.date, "dd 'de' MMMM", true),
      startTime: date.start_time,
      endTime: date.end_time
    }));
  }, []);

  const handleDuplicateEvent = useCallback(() => {
    dispatch({ type: ActionTypes.duplicateEvent, value: !duplicateEvent });
  }, [duplicateEvent]);

  const handleArchiveEvent = useCallback(() => {
    dispatch({ type: ActionTypes.archiveEvent, value: !archiveEvent });
  }, [archiveEvent]);

  const [showFunctionalitiesModal, setShowFunctionalitiesModal] = useState(false);

  const onCLoseFunctionalitiesModal = () => {
    setShowFunctionalitiesModal(false);
  };

  useEffect(() => {
    const dontShowTour = sessionStorage.getItem('dontShowTour');
    if (dontShowTour === 'true') return;

    if (window['IS_CREATE_EVENT'] === true && userData && data) {
      const userTours = userData?.tours;
      if (data.is_live === 0 && !userTours.includes('welcome')) {
        setShowFunctionalitiesModal(true);
        window['IS_CREATE_EVENT'] = false;
      }
    }
  }, [userData, data]);

  return (
    <S.Container>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        {isShownData && (
          <Row gutter={[5, 25]}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <PageTitle title='Dados Básicos' subtitle='Tenha uma visão geral sobre as configurações do seu evento' />
              <BasicDataOptionsButton
                optionsMenu={[
                  {
                    label: 'Duplicar',
                    icon: <CopyOutlined />,
                    onClick: handleDuplicateEvent
                  },
                  {
                    label: 'Excluir',
                    icon: <DeleteOutlined />,
                    onClick: handleArchiveEvent
                  }
                ]}
                isLive={!!data.is_live}
                eventId={data.id}
              />
            </Col>
            <Col span={24}>
              <DuplicateEvent
                isLive={!!data.is_live}
                eventId={data.id}
                handleClose={handleDuplicateEvent}
                open={duplicateEvent}
              />
              <ArchiveEvent
                eventId={data.id}
                onComplete={() => history.push('/my-events')}
                handleClose={handleArchiveEvent}
                open={archiveEvent}
              />
              <EventHeader
                isLive={!!data.is_live}
                image={CDN_URL + data?.image_path}
                title={data.title}
                date={getShortEventDates(data)}
                eventId={data.id}
              />
            </Col>

            <Col xs={24} md={12}>
              <EventLocal local={getEventLocation(data)} />
            </Col>
            <Col xs={24} md={12}>
              <EventDate date={getListEventDates(data)} />
            </Col>

            <Col span={24}>
              <Typography.Title level={5}>Descrição</Typography.Title>
              {renderDescription()}
            </Col>

            {data.type === 'presential' && (
              <Col span={24}>
                <Typography.Title level={5}>Chave do Evento</Typography.Title>
                <Typography.Text style={{ color: 'GrayText' }}>
                  Este código será útil na portaria no dia do evento para verificar se um ingresso é autêntico.
                </Typography.Text>
                <div style={{ margin: '5px 0', border: 'solid 1px #d9d9d9', padding: '5px' }}>
                  <KeyOutlined style={{ marginRight: '5px' }} />
                  <Typography.Text strong>{data.id.substr(-6, 6).toUpperCase()}</Typography.Text>
                </div>
                <Alert
                  type='info'
                  showIcon
                  message={`Na portaria ao receber os ingressos, poderá com um celular escanear o QRCode
                      dos ingressos, uma página web se abrirá com os dados do titular do ingresso, e em letras grandes a
                      CHAVE do seu EVENTO. Se for diferente de ${data.id.substr(-6, 6).toUpperCase()} então
                      certamente este ingresso NÃO é de seu evento.`}
                />
              </Col>
            )}
            <Col span={24}>
              <Typography.Title level={5}>Ingressos</Typography.Title>
              <EventTickets tickets={eventTicketsList} />
            </Col>
          </Row>
        )}
      </ReactPlaceholder>
      <FunctionalitiesModal open={showFunctionalitiesModal} onCancel={onCLoseFunctionalitiesModal} />
    </S.Container>
  );
}
