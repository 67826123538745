import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { ConfirmationNumberTwoTone, LocalOfferTwoTone, Mail, Refresh } from '@material-ui/icons';
import PushPinTwoToneIcon from '@mui/icons-material/PushPinTwoTone';
import { FilterIcon, ArrowIcon } from 'components/Shared/Icons';
import { hjTagging } from 'helpers/functions';

import PresenceFilterAdvanced from './FilterAdvanced';
import { SelectedOptType } from './FilterAdvanced/types';
import styles from './styles';
import { IProps } from './types';

const ToolbarPresence = ({
  classes,
  tags,
  eventDetail,
  eventIdSelected,
  user,
  handleUpdateParentState,
  filter,
  handleAddFilter,
  selectedParticipants,
  loadData,
  refreshData,
  handleSetFilterSelected,
  handleSubmitFilter,
  handleOpenModalResendTicket,
  handleOpenModalLink,
  handleOpenModalLabelManager,
  handleOpenModalSendEmailToParticipants,
  disableLabelManager
}: IProps) => {
  const [isOpenAdvanced, setIsOpenAdvanced] = useState(false);
  const [hasSelectedParticipants, setHasSelectedParticipants] = useState(false);
  const [timeoutSearch, setTimeoutSearch] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAttendees, setSelectedAttendees] = useState(null);
  const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
  const buttonOrderByRef = React.useRef<HTMLButtonElement>(null);
  const [isOpenOrderByMenu, setIsOpenOrderByMenu] = React.useState(false);

  const [orderByValue, setOrderByValue] = React.useState({
    text: 'Nº Ingresso (a-z)',
    value: 'invite_key asc',
    icon: <ArrowIcon />
  });

  useEffect(() => {
    setHasSelectedParticipants(!!selectedParticipants.length);
  }, [selectedParticipants]);

  const selectTagsHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedTags(selectedOpt);
    const allTagsValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, tags: allTagsValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allTagsValues, 'tags'));
  };

  const selectTicketsHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedTickets(selectedOpt);
    const allTicketValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, tickets: allTicketValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allTicketValues, 'tickets'));
  };

  const selectStatusHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedStatus(selectedOpt);
    const allStatusValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, status: allStatusValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allStatusValues, 'status'));
  };

  const selectAttendeesHandleChange = (selectedOpt: SelectedOptType[] | null) => {
    setSelectedAttendees(selectedOpt);
    const allAttendeesValues = selectedOpt?.map(option => option.value);
    const currFilter = { ...filter, attendance_situation: allAttendeesValues };
    handleUpdateParentState({ filter: currFilter }, () => handleAddFilter(allAttendeesValues, 'attendance_situation'));
  };

  const cleanFilter = () => {
    setSelectedTags(null);
    setSelectedTickets(null);
    setSelectedStatus(null);
    setSelectedAttendees(null);
    handleSetFilterSelected([
      { type: 'search', value: '' },
      { type: 'status', value: 'all' },
      { type: 'ticket_id', value: 'all' },
      { type: 'tags', value: 'all' }
    ]);

    //não pode resetar a ordenação, é uma coisa a parte
    if (filter?.order) {
      handleUpdateParentState({ filter: { search: filter.search, order: filter.order } });
      return;
    }

    handleUpdateParentState({ filter: { search: filter.search } });
  };

  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;

    hjTagging('page:presencelist-detail-filter');
    e.persist();
    filter[name] = value;

    handleUpdateParentState({ filter }, () => handleAddFilter(value, name));

    if (name === 'search' && (value.length >= 3 || value.length === 0)) {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
      setTimeoutSearch({
        timeoutSearch: setTimeout(() => {
          handleSubmitFilter();
        }, 2000)
      });
    }
  };

  const handleClickTooltip = () => {
    hjTagging('page:presencelist-detail-filter');
    setIsOpenAdvanced(!isOpenAdvanced);
  };

  const handleRefreshData = () => {
    if (selectAllIsChecked) {
      setSelectAllIsChecked(false);
    }
    refreshData();
  };

  const options = [
    { text: 'Participante (a-z)', value: 'name asc', icon: <ArrowIcon /> },
    { text: 'Participante (z-a)', value: 'name desc', icon: <ArrowIcon style={{ transform: 'scaleY(-1)' }} /> },
    { text: 'Nº Ingresso (a-z)', value: 'invite_key asc', icon: <ArrowIcon /> },
    { text: 'Nº Ingresso (z-a)', value: 'invite_key desc', icon: <ArrowIcon style={{ transform: 'scaleY(-1)' }} /> },
    { text: 'Comprador (a-z)', value: 'invoice_customer_name asc', icon: <ArrowIcon /> },
    {
      text: 'Comprador (z-a)',
      value: 'invoice_customer_name desc',
      icon: <ArrowIcon style={{ transform: 'scaleY(-1)' }} />
    }
  ] as const;

  type optionsType = typeof options[number];

  const setTheOrderByValue = (option: optionsType) => () => {
    setOrderByValue(option);
    setIsOpenOrderByMenu(false);
    loadData({ ...filter, order: option.value });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.toolbar}>
            <div className={classes.iconButtons}>
              <Tooltip title='Reenviar ingresso'>
                <span>
                  <IconButton
                    disabled={!hasSelectedParticipants}
                    color='primary'
                    id={'btn-resend-ticket-multiple'}
                    onClick={() => handleOpenModalResendTicket(true, null, true)}
                  >
                    <ConfirmationNumberTwoTone />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='Adicionar marcador'>
                <span>
                  <IconButton
                    disabled={!hasSelectedParticipants}
                    color='primary'
                    id={'btn-marker-multiple'}
                    onClick={() => {
                      if (selectedParticipants.length > 1) {
                        handleOpenModalLink(true, null, true);
                      } else {
                        handleOpenModalLink(true, selectedParticipants[0], false);
                      }
                    }}
                  >
                    <PushPinTwoToneIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='Gerar etiqueta'>
                <span>
                  <IconButton
                    disabled={!hasSelectedParticipants || disableLabelManager}
                    color='primary'
                    id={'btn-label-multiple'}
                    onClick={() => handleOpenModalLabelManager(true, selectedParticipants)}
                  >
                    <LocalOfferTwoTone />
                  </IconButton>
                </span>
              </Tooltip>

              {hasSelectedParticipants && handleOpenModalSendEmailToParticipants && (
                <Tooltip title='Enviar e-mail'>
                  <IconButton
                    color='primary'
                    id={'btn-label-send-email'}
                    onClick={() => handleOpenModalSendEmailToParticipants()}
                  >
                    <Mail />
                  </IconButton>
                </Tooltip>
              )}
            </div>

            <div className={classes.actionButtonsContainer}>
              <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setIsOpenOrderByMenu(false)}
                open={isOpenOrderByMenu}
                anchorEl={buttonOrderByRef.current}
                getContentAnchorEl={null}
              >
                {options.map(option => (
                  <MenuItem key={option.text} onClick={setTheOrderByValue(option)}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                      {option.icon}
                      <Typography>{option.text}</Typography>
                    </div>
                  </MenuItem>
                ))}
              </Menu>

              <Button
                ref={buttonOrderByRef}
                id='order-by-btn'
                onClick={() => setIsOpenOrderByMenu(true)}
                variant='outlined'
                startIcon={orderByValue.icon}
              >
                <Typography variant='subtitle1'>{orderByValue.text}</Typography>
              </Button>

              <Button
                variant='outlined'
                id={'btn-refresh-list'}
                startIcon={<Refresh style={{ color: '#000000' }} />}
                onClick={handleRefreshData}
              >
                <Typography variant='subtitle1'>Atualizar</Typography>
              </Button>

              <Tooltip title='Filtro avançado'>
                <Button
                  onClick={handleClickTooltip}
                  id='advanced-filter-btn'
                  variant={isOpenAdvanced ? 'contained' : 'outlined'}
                  {...(isOpenAdvanced && { color: 'primary' })}
                  startIcon={<FilterIcon {...(isOpenAdvanced && { fill: 'black' })} />}
                >
                  <Typography variant='subtitle1' style={isOpenAdvanced ? { color: 'black' } : null}>
                    Filtrar
                  </Typography>
                </Button>
              </Tooltip>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <PresenceFilterAdvanced
          tags={tags}
          tickets={eventDetail.tickets || []}
          loadData={loadData}
          filter={{ ...filter }}
          eventIdSelected={eventIdSelected}
          user={user}
          isOpen={isOpenAdvanced}
          handleChangeFilter={handleChangeFilter}
          selectTagsHandleChange={selectTagsHandleChange}
          selectTicketsHandleChange={selectTicketsHandleChange}
          selectStatusHandleChange={selectStatusHandleChange}
          selectAttendeesHandleChange={selectAttendeesHandleChange}
          cleanFilter={cleanFilter}
          submitFilter={handleSubmitFilter}
          selectedTags={selectedTags}
          selectedTickets={selectedTickets}
          selectedStatus={selectedStatus}
          selectedAttendees={selectedAttendees}
        />
      </Grid>
    </>
  );
};

export default withStyles(styles)(ToolbarPresence);
