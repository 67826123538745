import { IEvent, IEventList, IEventPost, LastUpdatedEvent } from 'interfaces/event';
import { EventPanelStatistics } from 'interfaces/eventStatics';
import { PaginationResponse } from 'interfaces/pagination';
import * as Rx from 'rxjs';
import * as RxOp from 'rxjs-operators';

import apiService, { ApiService } from '../api';
import { GetEventCheckInDetailsResponse } from './types';

export class EventService {
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  public list(params?: any): Rx.Observable<IEventList[]> {
    return this.apiService.post('/event/list', params).pipe(RxOp.map(response => response.data[0].data));
  }

  public listPastEvents(params?: any): Rx.Observable<any> {
    return this.apiService.post('/event/list_past', params).pipe(RxOp.map(response => response.data[0]));
  }

  public listLastEvents(params?: any): Rx.Observable<PaginationResponse<LastUpdatedEvent[]>> {
    return this.apiService
      .post('/event/list_current_last_modified', params)
      .pipe(RxOp.map(response => response.data[0]));
  }

  public listCurrentEvents(params?: any): Rx.Observable<any> {
    return this.apiService.post('/event/list_current', params).pipe(RxOp.map(response => response.data[0]));
  }

  public detail(eventId: string): Rx.Observable<any> {
    return this.apiService.get(`/event/detail/${eventId}`).pipe(RxOp.map(response => response.data[0]));
  }

  public details<T>(eventId: string): Rx.Observable<T> {
    return this.apiService.get(`/event/detail/${eventId}`).pipe(RxOp.map(response => response.data[0]));
  }

  public detailJobsLotPending<T>(eventId: string): Rx.Observable<T> {
    return this.apiService
      .get(`/event/event_detail_jobs_lot_pending/${eventId}`)
      .pipe(RxOp.map(response => response.data[0]));
  }

  public dashboardStatistics(eventId: string): Rx.Observable<EventPanelStatistics> {
    return this.apiService.get(`/statistics/event_dashboard/${eventId}`).pipe(RxOp.map(response => response));
  }

  public edit(eventId: string, params: Partial<IEvent | IEventPost>): Rx.Observable<any> {
    return this.apiService.post(`/event/edit/${eventId}`, params);
  }

  public editTemplate<T>(eventId: string, params: Partial<T>): Rx.Observable<any> {
    return this.apiService.post(`/event/edit/${eventId}`, params);
  }
  public availableSaleUrl(url: string): Rx.Observable<any> {
    return this.apiService.get(`/event/available_sale_url/${url}`);
  }

  public roles(): Rx.Observable<any[]> {
    return this.apiService.get('/user/roles').pipe(RxOp.map(({ data }) => data));
  }

  public createEvent(model: any): Rx.Observable<any> {
    return this.apiService.post('/event/create_event_ticket_lot', model);
  }

  public createLight(model: any): Rx.Observable<any> {
    return this.apiService.post('/event/create_light', model);
  }

  public editEvent(model: any): Rx.Observable<any> {
    return this.apiService.post('/event/update_event_ticket_lot', model);
  }

  public archiveEvent(data: any): Rx.Observable<any> {
    return this.apiService.post('/event/archive', data);
  }

  public delete(id: number): Rx.Observable<void> {
    return this.apiService.delete(`/user/${id}`);
  }

  public checkInDetails(hash: string): Rx.Observable<GetEventCheckInDetailsResponse> {
    return this.apiService.get(`/presence/checkin_get_detail/${hash}`).pipe(RxOp.map(response => response.data[0]));
  }
}

const eventService = new EventService(apiService);
export default eventService;
