import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Modal,
  Typography,
  Divider,
  Button,
  Form,
  Row,
  Col,
  Switch,
  Input as InputAntd,
  InputNumber
} from 'components/Shared/Antd';
import Field from 'components/Shared/Field';
import { BrazilIcon } from 'components/Shared/Icons';
import Input from 'components/Shared/Input';
import Toast from 'components/Shared/Toast';
import { cleanString } from 'helpers/functions';
import { IEditPresence } from 'interfaces/attendanceList';
import { useForm, FormProvider } from 'react-hook-form';
import InputMask from 'react-input-mask';
import attendanceListService from 'services/attendanceList';
import { InferType } from 'yup';

import validationSchema from './schema';

type Form = InferType<typeof validationSchema>;

type EditParticipantDialogProps = {
  data: IEditPresence;
  open: boolean;
  onCancel: () => void;
  onComplete: (newData: IEditPresence) => void;
  changeStatus?: string;
};

const centerCol = { display: 'flex', alignItems: 'center', gap: '8px', marginTop: '8px' };

const EditParticipantDialog = ({ data, open, onCancel, onComplete, changeStatus }: EditParticipantDialogProps) => {
  const form = useForm({
    defaultValues: {
      ddi: '55',
      is_international_doc: false,
      ticket_id: undefined,
      name: '',
      phone: '',
      email: '',
      document: ''
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const ddi = form.watch('ddi');
  const is_international_doc = form.watch('is_international_doc');
  const phone = form.watch('phone');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInternationalPhone, setIsInternationalPhone] = useState(false);

  const handlePasteDocument = e => {
    const document = e.clipboardData.getData('Text');
    form.setValue('document', cleanString(document));
    form.trigger('document');
  };

  const handlePastePhone = e => {
    const phone = e.clipboardData.getData('Text');
    form.setValue('phone', cleanString(phone));
    form.trigger('phone');
  };

  React.useEffect(() => {
    if (!data) return;

    Object.entries(data).forEach(([key, value]) => form.setValue(key as keyof Form, value));

    form.setValue('is_international_doc', data?.document_type === 'passport');

    if (!data?.phone) return;

    // corrige telefone no formato + (68) 92560-7149
    if (data?.phone?.match(/^\+\ \($/)) {
      form.setValue('ddi', '55');
      form.setValue('phone', data.phone.slice(2));
      return;
    }

    // telefone no formato +55 (83) 99856-9846
    if (data?.phone?.match(/(^\+?5{2})/gm)) {
      form.setValue('ddi', '55');
      form.setValue('phone', data?.phone?.slice(3));
      return;
    }

    // telefone no formato 15963259785 ou (42) 3626-6261
    if (data?.phone?.match(/(^\([0-9]{2}\))|^[0-9]+/gm)) {
      form.setValue('ddi', '55');
      form.setValue('phone', data?.phone);
      return;
    }

    //se número for internacional || condição extra pra numeros no formato +1 999999999
    if (data?.phone?.match(/(^\+(?!55)[0-9]{2})/gm) || data?.phone?.match(/^\+[0-9]\ [0-9]{2}/)) {
      setIsInternationalPhone(true);
      const firstPart = data?.phone?.substring(0, data?.phone?.indexOf(' '));
      const secondPart = data?.phone?.substring(data?.phone?.indexOf(' ') + 1);
      if (firstPart && secondPart) {
        form.setValue('ddi', cleanString(firstPart));
        form.setValue('phone', cleanString(secondPart));
      } else {
        form.setValue('phone', firstPart);
      }
    }
  }, []);

  const onIsInternationalDocChange = boolean => {
    if (boolean) {
      form.setValue('phone', cleanString(form.getValues('phone')));
      form.setValue('document', cleanString(form.getValues('document')));
    }
    form.setValue('is_international_doc', boolean);
    form.trigger('document');
  };

  const onSubmit = async ({ ddi, name, email, document, is_international_doc, phone }: Form) => {
    const payload: IEditPresence = {
      id: data.id,
      email,
      name,
      phone: phone ? `+${ddi.trim()} ${phone.trim()}` : '',
      document: cleanString(document),
      document_type: is_international_doc ? ('passport' as const) : ('cpf' as const),
      custom_fields: {},
      status: changeStatus ?? 'presence_confirmed'
    };

    setIsSubmitting(true);

    attendanceListService.editByInviteKey(payload).subscribe(
      () => {
        Toast.show('Dados alterados com sucesso');
        setIsSubmitting(false);
        onComplete(payload);
      },
      ({ data: err }) => {
        if (err.details) {
          Toast.error(err.details);
        }
      }
    );
    setIsSubmitting(false);
  };

  return (
    <Modal
      zIndex={4000}
      open={open}
      onCancel={onCancel}
      width={800}
      centered
      title={
        <>
          <Typography.Title level={4}>Editar Participante</Typography.Title>
          <Divider />
        </>
      }
      footer={[
        <React.Fragment key='primary'>
          <Divider />
          <Button
            loading={isSubmitting}
            type='primary'
            id='add-EditParticipant-btn'
            htmlType='submit'
            size='large'
            onClick={form.handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Aguarde...' : 'Concluir'}
          </Button>
        </React.Fragment>
      ]}
    >
      <FormProvider {...form}>
        <Form>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Input legend='Nome' placeholder='Nome do Participante' name='name' key='participant-name-input' />
            </Col>
            <Col md={12} xs={24}>
              <Input legend='E-mail' placeholder='Ex: fulano@eduzz.com' name='email' key='participant-email-input' />
            </Col>
            <Col md={12} xs={24}>
              <Typography.Text>{is_international_doc ? 'DOC' : 'CPF'}</Typography.Text>
              <Field
                name='document'
                render={
                  is_international_doc ? (
                    <InputAntd
                      size='large'
                      placeholder='Ex.: 999.888.777-66'
                      maxLength={20}
                      key='participant-doc-input'
                    />
                  ) : (
                    <InputMask mask='999.999.999-99' onPaste={handlePasteDocument}>
                      <InputAntd
                        size='large'
                        placeholder='Ex.: 999.888.777-66'
                        maxLength={20}
                        key='participant-cpf-input'
                      />
                    </InputMask>
                  )
                }
              />
            </Col>
            <Col md={12} xs={24} style={centerCol}>
              <Field
                name='is_international_doc'
                onChange={onIsInternationalDocChange}
                render={<Switch checked={is_international_doc} />}
              />
              <Typography.Text>Documento internacional</Typography.Text>
            </Col>
            <Col md={12} xs={24}>
              <Typography.Text>Telefone</Typography.Text>
              <div style={{ display: 'flex' }}>
                {isInternationalPhone && (
                  <div tabIndex={-1} style={{ flexBasis: '35%' }}>
                    <Field
                      name='ddi'
                      tabIndex={-1}
                      render={
                        <InputNumber
                          controls={false}
                          prefix={
                            ddi == '55' ? (
                              <>
                                <BrazilIcon />
                                <span>+</span>
                              </>
                            ) : (
                              <span>+</span>
                            )
                          }
                          size='large'
                          placeholder='DDI'
                          maxLength={4}
                        />
                      }
                    />
                  </div>
                )}
                <div style={{ width: '100%' }}>
                  <Field
                    name='phone'
                    render={
                      isInternationalPhone ? (
                        <InputAntd size='large' placeholder='Nº do telefone' maxLength={20} />
                      ) : (
                        <InputMask
                          onPaste={handlePastePhone}
                          mask={phone && cleanString(phone)[2] === '3' ? '(99) 9999-9999' : '(99) 99999-9999'}
                        >
                          <InputAntd size='large' placeholder='Código DDD + Nº do telefone' maxLength={20} />
                        </InputMask>
                      )
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default EditParticipantDialog;
