import React, { useEffect, useState, useMemo } from 'react';

import ArchiveEvent from 'components/Pages/Admin/MyEvents/ArchiveEvent';
import DuplicateEvent from 'components/Pages/Admin/MyEvents/DuplicateEvent';
import { SaleLinks } from 'components/Presentational';
import CustomLoader from 'components/Shared/CustomLoader';
import Tour from 'components/Shared/Tour';
import { getTargetElement } from 'components/Shared/Tour/helpers';
import { useCustomObservable } from 'hooks';
import useTabTour from 'hooks/useTabTour';
import { IEvent } from 'interfaces/event';
import ReactPlaceholder from 'react-placeholder/lib';
import { useParams, useHistory } from 'react-router-dom';
import eventService from 'services/event';
import { titlePageService } from 'services/routes';

import useUpdateTicketListUntilAllCntCodsAreCreated from './hooks';
import * as S from './styles';

const SaleLinksPanel = () => {
  const { id } = useParams<{ id: string }>();
  const { showTour, openTour, closeTour } = useTabTour('sale-links');

  const [archiveEvent, setArchiveEvent] = useState(false);
  const [duplicateEvent, setDuplicateEvent] = useState(false);

  const handleDuplicateEvent = () => {
    setDuplicateEvent(prevState => !prevState);
  };

  const handleArchiveEvent = () => {
    setArchiveEvent(prevState => !prevState);
  };

  const { value: data, loading } = useCustomObservable<IEvent, void, void>(
    () => eventService.details<IEvent>(id),
    null
  );

  useEffect(() => {
    titlePageService.set(
      ['Meus Eventos'],
      [
        { label: 'Meus Eventos', path: '/my-events' },
        { label: data?.title, path: `/my-events/${data?.id}/basic-data` },
        { label: 'Links de divulgação', path: `/my-events/${data?.id}/sale-links` }
      ],
      { label: 'Dados básicos', path: `/my-events/${data?.id}/basic-data` }
    );
  }, [data?.id, data?.title]);

  const { eventTicketsList } = useUpdateTicketListUntilAllCntCodsAreCreated(data, id);

  const history = useHistory();

  const steps = useMemo(() => {
    if (!eventTicketsList) {
      return [];
    }

    type TourStep = {
      title: string;
      description: string;
      target: () => HTMLElement;
      isLastStep?: boolean;
    };

    const tourSteps: TourStep[] = [];

    const eventHasSalePage = id !== data?.sale_url;
    const hasNewSaleUrl = !!window['newSaleUrl'];

    if (!eventHasSalePage && !hasNewSaleUrl) {
      tourSteps.push({
        title: 'Links de divulgação',
        description:
          'Você ainda não tem uma página de vendas. Você pode criar uma página de vendas de forma gratuita na aba Página do Evento.',
        target: null
      });
    }

    if (eventHasSalePage || hasNewSaleUrl) {
      tourSteps.push({
        title: 'Links de divulgação',
        description:
          'Use este link para divulgar sua página de vendas da Blinket. Você pode personaliza-la de forma gratuita.',
        target: getTargetElement('div#sales-page-link')
      });
    }

    const eventHasOrbitPage = !!data?.partner_sale_url;

    if (eventHasOrbitPage) {
      tourSteps.push({
        title: 'Links de divulgação',
        description: 'Use este link para divulgar sua página de vendas da Orbit Pages.',
        target: getTargetElement('div#orbit-page-sale-link')
      });
    }

    const hasLotLinkAutoChange = eventTicketsList.some(ticket => !!ticket.cnt_cod);

    if (hasLotLinkAutoChange) {
      tourSteps.push({
        title: 'Links de divulgação',
        description:
          'Caso tenha sua própria página de vendas, use o link para virada de lote automático. Quando um lote expira, é alterado para o próximo lote sem precisar de configuração.',
        target: getTargetElement('div#lot-link-auto-change')
      });
    }

    const lastStep = {
      title: 'Links de divulgação',
      description: 'Para divulgar um lote em específico, use este link.',
      target: getTargetElement('div#lot-link'),
      isLastStep: true
    };

    tourSteps.push(lastStep);

    return tourSteps;
  }, [data, eventTicketsList, id]);

  return (
    <S.Container>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        <SaleLinks
          tickets={eventTicketsList}
          saleLink={data?.sale_url}
          orbitPagesUrl={data?.partner_sale_url}
          openTour={openTour}
        />
        <DuplicateEvent
          eventId={data?.id}
          handleClose={handleDuplicateEvent}
          isLive={!!data?.is_live}
          open={duplicateEvent}
        />
        <ArchiveEvent
          eventId={data?.id}
          onComplete={() => history.push('/my-events')}
          handleClose={handleArchiveEvent}
          open={archiveEvent}
        />
      </ReactPlaceholder>
      {!loading && <Tour steps={steps} open={showTour} onClose={closeTour} />}
    </S.Container>
  );
};

export default SaleLinksPanel;
