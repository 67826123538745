import { useEffect, useMemo } from 'react';

import { Grid, TextField, Tooltip } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button } from 'antd';
import { CharCount } from 'components/Presentational';
import CurrencyFormat from 'components/Presentational/CurrencyFormat';
import { getHours, max, setHours, add } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { IEventForm, ILoteForm } from 'interfaces/event';
import { useFormContext, Controller, useWatch } from 'react-hook-form';

import * as S from './styles';

const CharCounter = ({ max, align, ticketIndex, index }) => {
  const { control } = useFormContext();
  const ticketName = useWatch({ control: control, name: `ticket.${ticketIndex}.lot.${index}.name` });
  return <CharCount value={ticketName} align={align} max={max} />;
};

interface LotProps {
  index: number;
  onDelete: (lotIndex: number, lot: ILoteForm) => void;
  ticketIndex: number;
  ticketWillBeFree: boolean;
  ticketWillBeUnlimited: boolean;
  onOk: (ticketIndex: number, index: number, lot: ILoteForm) => void;
  onCancel: () => void;
  isEditing: boolean;
}

const Lot = ({
  index,
  onDelete,
  ticketIndex,
  ticketWillBeFree,
  ticketWillBeUnlimited,
  isEditing,
  onOk,
  onCancel
}: LotProps) => {
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    unregister
  } = useFormContext<IEventForm>();

  const eventDates = getValues('event_dates');
  const lotDateStart = getValues(`ticket.${ticketIndex}.lot.${index}.dateStart`);
  const lotName = getValues(`ticket.${ticketIndex}.lot.${index}.name`);

  const eventDatesWithEndTime = useMemo(() => {
    return eventDates?.map(eventDate => {
      const endDate = eventDate.date;
      const endHour = getHours(new Date(eventDate.end_time));

      setHours(endDate, endHour);

      return endDate;
    });
  }, [eventDates]);

  const endEventDate = useMemo(() => {
    return max(eventDatesWithEndTime);
  }, [eventDatesWithEndTime]);

  useEffect(() => {
    if (ticketWillBeFree) {
      setValue(`ticket.${ticketIndex}.lot.${index}.value`, 0);
      clearErrors(`ticket.${ticketIndex}.lot.${index}.value`);
    }
  }, [clearErrors, index, setValue, ticketIndex, ticketWillBeFree]);

  useEffect(() => {
    if (ticketWillBeUnlimited) {
      setValue(`ticket.${ticketIndex}.lot.${index}.quantity`, null);
      clearErrors(`ticket.${ticketIndex}.lot.${index}.quantity`);
    }
  }, [clearErrors, index, setValue, ticketIndex, ticketWillBeUnlimited]);

  return (
    <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
      <S.Wrapper container item spacing={3} is_active={!!getValues(`ticket.${ticketIndex}.lot.${index}.is_active`)}>
        <Grid container item spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.ticket?.[ticketIndex]?.lot?.[index]?.name}
              fullWidth
              helperText={errors.ticket?.[ticketIndex]?.lot?.[index]?.name?.message}
              id='name-text-field'
              defaultValue={lotName || ''}
              InputLabelProps={{
                shrink: true,
                style: { pointerEvents: 'auto' }
              }}
              inputProps={{ maxLength: 40 }}
              label={
                <S.LabelWrapper>
                  <span>Nome do lote *</span>
                  <Tooltip arrow placement='top-start' title='Insira um nome para o lote do seu evento'>
                    <S.CustomizedHelpOutlineIcon />
                  </Tooltip>
                </S.LabelWrapper>
              }
              type='text'
              variant='outlined'
              {...register(`ticket.${ticketIndex}.lot.${index}.name`)}
            />
            <CharCounter align='right' max={30} index={index} ticketIndex={ticketIndex} />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              disabled={ticketWillBeUnlimited}
              error={!!errors.ticket?.[ticketIndex]?.lot?.[index]?.quantity}
              fullWidth
              helperText={errors.ticket?.[ticketIndex]?.lot?.[index]?.quantity?.message}
              id='quantity-text-field'
              InputLabelProps={{
                shrink: true
              }}
              label='Quantidade'
              placeholder='Insira uma quantidade de ingressos'
              type='number'
              variant='outlined'
              {...register(`ticket.${ticketIndex}.lot.${index}.quantity`)}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Controller
              control={control}
              rules={{
                required: !ticketWillBeFree
              }}
              name={`ticket.${ticketIndex}.lot.${index}.value`}
              render={({ field: { onChange, value } }) => (
                <TextField
                  key={`ticket-value-${ticketWillBeFree}`}
                  value={value}
                  onChange={onChange}
                  disabled={ticketWillBeFree}
                  error={!!errors.ticket?.[ticketIndex]?.lot?.[index]?.value}
                  fullWidth
                  helperText={errors.ticket?.[ticketIndex]?.lot?.[index]?.value?.message}
                  id='unitary-value-text-field'
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputComponent: CurrencyFormat as any
                  }}
                  label='Valor unitário'
                  variant='outlined'
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              defaultValue={null}
              name={`ticket.${ticketIndex}.lot.${index}.dateStart`}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  maxDateTime={endEventDate}
                  onChange={onChange}
                  label='Início das vendas *'
                  disablePast={!isEditing}
                  data-testid={`lot-dateStart-${index}`}
                  value={value}
                  sx={{ width: '100%' }}
                  slotProps={{
                    layout: {
                      sx: {
                        '.MuiPickersLayout-contentWrapper': {
                          gridColumn: 1
                        }
                      }
                    }
                  }}
                  desktopModeMediaQuery='@media (pointer: fine)'
                />
              )}
            />
            {!!errors.ticket?.[ticketIndex]?.lot?.[index]?.dateStart?.message && (
              <S.ErrorMessage>{errors.ticket?.[ticketIndex]?.lot?.[index]?.dateStart?.message}</S.ErrorMessage>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              defaultValue={null}
              name={`ticket.${ticketIndex}.lot.${index}.dateEnd`}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <DateTimePicker
                  minDateTime={add(lotDateStart, { minutes: 1 }) || new Date()}
                  maxDateTime={endEventDate}
                  disablePast={!isEditing}
                  onChange={onChange}
                  label='Fim das vendas *'
                  sx={{ width: '100%' }}
                  data-testid={`lot-dateEnd-${index}`}
                  value={value}
                  slotProps={{
                    layout: {
                      sx: {
                        '.MuiPickersLayout-contentWrapper': {
                          gridColumn: 1
                        }
                      }
                    }
                  }}
                />
              )}
            />
            {!!errors.ticket?.[ticketIndex]?.lot?.[index]?.dateEnd?.message && (
              <S.ErrorMessage>{errors.ticket?.[ticketIndex]?.lot?.[index]?.dateEnd?.message}</S.ErrorMessage>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type='primary'
              onClick={() => onOk(ticketIndex, index, getValues(`ticket.${ticketIndex}.lot.${index}`))}
              style={{ marginRight: '10px' }}
            >
              Salvar
            </Button>

            <Button
              htmlType='reset'
              onClick={() => {
                if (!(getValues(`ticket.${ticketIndex}.lot`)?.length > 1) || isEditing) {
                  unregister(
                    [
                      `ticket.${ticketIndex}.lot.${index}.dateStart`,
                      `ticket.${ticketIndex}.lot.${index}.dateEnd`,
                      `ticket.${ticketIndex}.lot.${index}.value`,
                      `ticket.${ticketIndex}.lot.${index}.quantity`,
                      `ticket.${ticketIndex}.lot.${index}.name`
                    ],
                    {
                      keepValue: true,
                      keepDefaultValue: true
                    }
                  );
                  onCancel();
                  return;
                }
                onDelete(index, getValues(`ticket.${ticketIndex}.lot.${index}`));
              }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </S.Wrapper>
    </LocalizationProvider>
  );
};

export default Lot;
