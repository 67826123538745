import { getHours, setHours, isAfter, isBefore, getMinutes, setMinutes } from 'date-fns';
import { ITicketForm, IEventForm, DateDetail } from 'interfaces/event';
import { date, object, string, number, array, NumberSchema, TestContext, AnyObjectSchema, mixed, boolean } from 'yup';

interface YupContext extends TestContext<AnyObjectSchema> {
  from: {
    value: unknown;
  }[];
}

const REQUIRED_MSG = 'Campo obrigatório';

const getEndDateOfEventDates = (eventDates: DateDetail[]) => {
  return eventDates.reduce((acumulator, current) => {
    const endTime = getHours(new Date(current.end_time));
    const endMinutes = getMinutes(new Date(current.end_time));
    const currentDeteWithEndTime = setHours(current.date, endTime);
    const currentDeteWithEndMinutes = setMinutes(currentDeteWithEndTime, endMinutes);

    if (!acumulator) {
      return currentDeteWithEndMinutes;
    }

    if (currentDeteWithEndMinutes > acumulator) {
      return currentDeteWithEndMinutes;
    }

    return acumulator;
  }, new Date());
};

const testEndEventDateIsAfterOfValue = (eventDates: DateDetail[], value: Date) => {
  const endDateOfEventDates = getEndDateOfEventDates(eventDates);

  return !isAfter(value, endDateOfEventDates);
};

export const schemaFormOne = object({
  title: string().trim().min(5, 'Mínimo 5 caracteres').max(100, 'Máximo 100 caracteres').required(REQUIRED_MSG),
  helpsupport_email: string().email('Deve ser um email válido').trim().max(255, 'Máximo 255 caracteres'),
  description: string()
    .trim()
    .min(50, 'Mínimo 50 caracteres')
    .max(2000, 'Máximo 2000 caracteres')
    .required(REQUIRED_MSG),
  image_base64: string().when('image_path', {
    is: undefined,
    then: imageSchema => imageSchema.typeError('Selecione uma imagem').required('Selecione uma imagem'),
    otherwise: imageSchema => imageSchema.nullable()
  }),
  hasReserveTime: boolean(),
  reserve_time: string()
    .when('hasReserveTime', {
      is: true,
      then: schema =>
        schema.test('reserve-time', (value, context: YupContext) => {
          const intValue = parseInt(value);

          if (value[0] === '0' && value.length > 1) {
            return context.createError({ message: 'Remova o 0 à esquerda' });
          }

          if (intValue > 15) {
            return context.createError({ message: 'Valor máximo 15' });
          }

          if (intValue < 1) {
            return context.createError({ message: 'Valor mínimo 1' });
          }

          return true;
        })
    })
    .matches(/^\d+$/, 'apenas números')
    .required()
    .typeError('Campo obrigatório')
});

export const schemaFormTwo = object({
  stream_url: string().url('URL inválida').nullable(),
  city: string().when('type', {
    is: 'presential',
    then: city => city.required('Campo obrigatório'),
    otherwise: city => city.nullable()
  }),
  state: string().when('type', {
    is: 'presential',
    then: state => state.required('Campo obrigatório'),
    otherwise: state => state.nullable()
  }),
  country: string().when('type', {
    is: 'presential',
    then: country => country.required('Campo obrigatório'),
    otherwise: country => country.nullable()
  }),
  number: string().max(10, 'Máximo 10 caracteres').nullable()
});

const MAX_NUMBER = 9999999;
export const schemaFormThree = object({
  ticket: array().of(
    object({
      name: string()
        .trim()
        .required('Campo obrigatório')
        .min(2, 'Mínimo de 2 caracteres')
        .max(30, 'Máximo 30 caracteres'),
      buy_minimum: number()
        .typeError('Obrigatório')
        .required('Campo obrigatório')
        .min(1, 'Deve ser maior que 0')
        .max(MAX_NUMBER, `Deve ser menor que ${MAX_NUMBER}`),
      buy_limit: number()
        .typeError('Obrigatório')
        .required('Campo obrigatório')
        .max(MAX_NUMBER, `Deve ser menor que ${MAX_NUMBER}`)
        .when('buy_minimum', (buy_minimum: number, schema: NumberSchema) =>
          schema.min(buy_minimum, 'Max. de compra deve ser maior ou igual ao Mín. de compra')
        ),
      lot: array().of(
        object({
          name: string()
            .trim()
            .min(2, 'Mínimo de 2 caracteres')
            .max(30, 'Máximo 30 caracteres')
            .test('valid-name', 'Nome do lote não pode se repetir no mesmo ingresso.', (value, context: YupContext) => {
              if (!value) {
                return context.createError({ message: 'Valor obrigatório' });
              }

              const { lot } = context?.from[1]?.value as ITicketForm;

              if (lot.filter(lot => lot.name === value.trim()).length > 1) {
                return context.createError({ message: 'Insira um nome de lote válido' });
              }

              return true;
            }),
          dateStart: date()
            .typeError('Campo obrigatório')
            .test(
              'valid-start-sale-date',
              'Data deve ser maior que a data atual ou data de criação do evento.',
              (value, context: YupContext) => {
                const created = context?.parent?.created;
                const limitDate = new Date('01-01-2000');
                const isBeforeThanLimitDate = isBefore(value, limitDate);
                const isBeforeThanToday = isBefore(value, new Date());
                return !((created && isBeforeThanLimitDate) || (!created && isBeforeThanToday));
              }
            )
            .test(
              'valid-start-sale-date',
              'Data deve ser menor que a data final do evento.',
              (value, context: YupContext) => {
                const { event_dates } = context?.from[2]?.value as IEventForm;

                return testEndEventDateIsAfterOfValue(event_dates, value);
              }
            ),
          dateEnd: date()
            .typeError('Campo obrigatório')
            .test(
              'valid-end-sale-date',
              'Data deve ser maior que a data inicial das vendas.',
              (value, context: YupContext) => {
                const startSaleDate = new Date(context?.parent?.dateStart);

                return !isBefore(value, startSaleDate);
              }
            )
            .test(
              'valid-end-sale-dat-2',
              'Data deve ser menor que a data final do evento.',
              // eslint-disable-next-line sonarjs/no-identical-functions
              (value, context: YupContext) => {
                const { event_dates } = context?.from[2]?.value as IEventForm;

                return testEndEventDateIsAfterOfValue(event_dates, value);
              }
            ),
          quantity: mixed().test('valid-quantity', (value, context: YupContext) => {
            const { is_unlimited: isUnlimited } = context?.from[1]?.value as ITicketForm;

            if (isUnlimited) {
              return true;
            }

            if (!value) {
              return context.createError({ message: 'Valor obrigatório' });
            }

            if (value === '') {
              return context.createError({ message: 'Valor obrigatório' });
            }

            if (value <= 0) {
              return context.createError({ message: 'Valor deve ser maior que 0' });
            }

            if (value >= MAX_NUMBER) {
              return context.createError({ message: `Valor deve ser menor que ${MAX_NUMBER}` });
            }

            return true;
          }),
          value: mixed().test('valid-quantity-sold', (value, context: YupContext) => {
            const { is_paid } = context?.from[1]?.value as ITicketForm;
            if (!is_paid) {
              return true;
            }

            if (value === '') {
              return context.createError({ message: 'Valor obrigatório' });
            }

            if (value <= 0) {
              return context.createError({ message: 'Valor deve ser maior que 0' });
            }

            if (value >= MAX_NUMBER) {
              return context.createError({ message: `Valor deve ser menor que ${MAX_NUMBER}` });
            }

            return true;
          })
        })
      )
    })
  )
});
