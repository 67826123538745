import { HotjarEvents, submitEvent } from 'hotjar';
import { npsService } from 'services/npsService';

const SATISFACTION_SURVEY_ID = 835265;
const EXPIRATION_IN_DAYS = 90;

export const triggerSatisfactionSurvey = () =>
  setHotjarSurvey(SATISFACTION_SURVEY_ID, HotjarEvents.SATISFACTION_SURVEY);

const setHotjarSurvey = async (id: number, event: HotjarEvents) => {
  const lastAnswer = await npsService.getSurveyLastAnswer(id);

  if (lastAnswer) {
    const isSurveyExpired = lastAnswer.answered && lastAnswer.days_count >= EXPIRATION_IN_DAYS;
    const shouldShowSurvey = !lastAnswer.answered || isSurveyExpired;

    if (shouldShowSurvey) submitEvent(event);
  }
};
