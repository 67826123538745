import React, { useEffect } from 'react';

import { Onboarding } from 'interfaces/onboarding';
import onboardingService from 'services/onboarding';
import { createContext, useContextSelector } from 'use-context-selector';

import useObservableCallback from '@eduzz/houston-hooks/useObservableCallback';

import { OnboardingContextData, Props } from './types';

const OnboardingContext = createContext<OnboardingContextData>(null);

export const OnboardingProvider = ({ children }: Props) => {
  const [handleUpdateFlags, onboardingFlags] = useObservableCallback<Onboarding, any>(
    () => onboardingService.detail(),
    []
  );

  useEffect(() => {
    handleUpdateFlags();
  }, [handleUpdateFlags]);

  return (
    <OnboardingContext.Provider value={{ onboardingFlags, handleUpdateFlags }}>{children}</OnboardingContext.Provider>
  );
};

export function useOnboardingFlags() {
  const onboardingFlags = useContextSelector(OnboardingContext, onboarding => onboarding?.onboardingFlags);
  return { onboardingFlags };
}

export function useUpdateFlags() {
  const handleUpdateFlags = useContextSelector(OnboardingContext, onboarding => onboarding?.handleUpdateFlags);
  return { handleUpdateFlags };
}
