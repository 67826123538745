import type { AdvertisementlotLotInfos } from 'components/Presentational/SaleLinks/types';
import { IEvent, IEventPageEdit, IEventPost } from 'interfaces/event';
import * as Rx from 'rxjs';
import RxOp from 'rxjs-operators';
import eventService from 'services/event';
import { CDN_URL } from 'settings';
import { templatePages } from 'utils';

import { EventPageData, FormEventPage } from './types';

class EventPageDataService {
  private getEventLotLinks(data: IEvent): Array<AdvertisementlotLotInfos[]> {
    const mapLotItens = (cnt_cod: number, name: string, index: number): AdvertisementlotLotInfos => {
      return {
        name: `${name} - Lote ${index + 1}`,
        cnt_cod
      };
    };

    return data?.tickets.map(item => item.lot.map((lot, index) => mapLotItens(lot.cnt_cod, item.name, index)));
  }
  private getEventPage(data: IEvent): EventPageData {
    const pageConfig: IEventPageEdit = JSON.parse(data.page_config);
    const templatePage = Object.keys(templatePages).find(
      template => templatePages[template].name === pageConfig.templateVersion
    );

    const dataEventPage: EventPageData = {
      eventHeader: {
        isLive: data.is_live,
        eventId: data.id,
        title: data.title,
        date: { start: `${data.start_date} às ${data.start_hour}`, end: `${data.end_date} às ${data.end_hour}` },
        image: CDN_URL + data?.image_path
      },
      advertisementLinks: {
        saleLink: data.sale_url,
        advertisementLot: this.getEventLotLinks(data)
      },
      page_config: pageConfig,
      partnerConfig: {
        templateType: data.template_type || templatePage,
        isPartnerTemplate: !!templatePages[data.template_type]?.isPartner,
        partnerEditUrl: data.partner_edit_url || `https://app.orbitpag.es/eduzz-blinket?blinket_event_id=${data.id}`,
        partnerSaleUrl: data.partner_sale_url,
        isPagePublished: !!data.partner_edit_url
      }
    };
    return dataEventPage;
  }

  private putEventPage(data: FormEventPage): Partial<IEventPost> {
    const templatePage = templatePages[data.template_type];

    if (templatePage.isPartner) {
      delete data.sale_url;
    }

    const eventPageConfigChanges: Partial<IEventPageEdit> = {
      ...(!templatePage.isPartner && { templateVersion: templatePage.name, pixel_key: data.pixel_key })
    };

    const eventPageConfig: IEventPageEdit = {
      ...data.page_config,
      ...eventPageConfigChanges
    };
    const eventPageData: Partial<IEventPost> = {
      ...data,
      page_config: eventPageConfig,
      draft_page_config: eventPageConfig,
      template_type: data.template_type
    };

    return eventPageData;
  }

  public get(id: string): Rx.Observable<EventPageData> {
    return eventService.details<IEvent>(id).pipe(RxOp.map(response => this.getEventPage(response)));
  }

  public put(id: string, eventPageData: FormEventPage) {
    return eventService.edit(id, this.putEventPage(eventPageData)).pipe(RxOp.logError());
  }
}
const eventPageService = new EventPageDataService();
export default eventPageService;
