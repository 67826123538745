import React, { FC, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';
import { SvgIcon } from 'components/Shared/SvgIcon';
import { hjTagging } from 'helpers/functions';
import { ITags } from 'interfaces/tags';
import { v4 } from 'uuid';

import MarkerEditDialogItem from './Item';
import styles from './styles';
import { IProps } from './types';

const MarkerEditDialog: FC<IProps> = ({
  opened,
  classes,
  markers,
  onComplete,
  onCancel,
  onMarkerRemoved,
  onCompleteEdit
}) => {
  hjTagging('page:presencelist-markermanager');
  const markerDetails: ITags = {
    id: v4(),
    title: '',
    color: '',
    isNew: false
  };

  const [model] = useState({
    ...markerDetails,
    color: '#000000'
  });
  const [marker, setMarker] = useState<ITags[]>([...markers]);

  function handleMarkerRemoved(tag: ITags) {
    const newMarkers = marker.filter(item => item.id !== tag.id);
    setMarker(newMarkers);
    if (!tag.isNew) {
      onMarkerRemoved(tag);
    }
  }

  function onCompleteAddMarker(data: ITags) {
    const newMarkers = marker.map(item => {
      let newItem = { ...item };
      if (item.id === data.id_temp) {
        newItem = { ...data };
        delete newItem.isNew;
        delete newItem.id_temp;
      }
      return { ...newItem };
    });
    setMarker(newMarkers);
    onComplete();
  }

  function addMarker() {
    const newMarker = { ...markerDetails };
    newMarker.id = v4();
    newMarker.title = model.title;
    newMarker.color = model.color;
    newMarker.isNew = true;

    setMarker([{ ...newMarker }, ...marker]);
  }

  return (
    <Dialog
      open={opened}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth='sm'
      style={{ overflow: 'auto' }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <div id='markerdialog-headertitle'>Gerenciar Marcadores</div>
          <IconButton onClick={onCancel} style={{ color: 'black' }} id='markerdialog-closedialog-button'>
            <CloseOutlined />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {marker.length > 0 &&
          marker.map(item => (
            <MarkerEditDialogItem
              key={item.id}
              marker={item}
              onCompleteEdit={onCompleteEdit}
              onComplete={onCompleteAddMarker}
              onMarkerRemoved={handleMarkerRemoved}
            />
          ))}

        {!marker.length && (
          <Grid container>
            <Grid item sm={12}>
              <Typography className={classes.noMarker}>
                <SvgIcon name='labelFilled' />
                <p>
                  <strong>Nenhum item cadastrado</strong>
                </p>
                <p>Adicione um marcador abaixo e classifique seus participantes de maneira fácil!</p>
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions
        style={{
          margin: 0,
          borderTop: '1px solid #e0e2f8',
          padding: '8px 21px'
        }}
      >
        <div className={classes.actionButtons}>
          <Button type='primary' id='markerdialog-addmarker-button' onClick={addMarker}>
            Adicionar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(MarkerEditDialog);
