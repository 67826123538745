import * as Rx from 'rxjs';
import RxOp, { filter, map } from 'rxjs-operators';

import apiService, { ApiService } from './api';

export class TicketService {
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  public delete(id: string): Rx.Observable<void> {
    return this.apiService.delete(`/ticket/delete/${id}`);
  }

  public deactivate(id: string): Rx.Observable<void> {
    return this.apiService.post(`/ticket/deactivate/${id}`, {}).pipe(
      map(({ response }) => response),
      filter(response => response !== undefined)
    );
  }

  public print(eventId: string, inviteKey?: string): Rx.Observable<any> {
    return this.apiService
      .get(`/ticket/print/${eventId}${inviteKey ? '/' + inviteKey : ''}`)
      .pipe(RxOp.map(response => response.data));
  }
}

const ticketService = new TicketService(apiService);
export default ticketService;
