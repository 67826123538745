export const ENV = (process.env.REACT_APP_ENV || 'production').trim();

export const API_ENDPOINT = (process.env.REACT_APP_API_ENDPOINT || '').trim();

export const CDN_ENDPOINT = (process.env.REACT_APP_CDN_ENDPOINT || '').trim();
export const CDN_URL = (process.env.REACT_APP_CDN_URL || '').trim();
export const GOOGLE_MAPS_PLACE_APIKEY = (process.env.REACT_APP_GOOGLE_MAPS_PLACE_APIKEY || '').trim();
export const COOKIE_DOMAIN = (process.env.REACT_APP_COOKIE_DOMAIN || '').trim();

export const SENTRY_KEY = (process.env.REACT_APP_SENTRY_KEY || '').trim();

export const TOAST_DEFAULT_TIMEOUT = 3000;
export const TOAST_ERROR_TIMEOUT = 5000;

export const IS_DEVELOPMENT = process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'homolog';
export const IS_PROD = ENV === 'production';

export const REACT_TOKEN = (process.env.REACT_APP_TOKEN || '').trim();
export const REACT_SECRET = (process.env.REACT_APP_SECRET || '').trim();
export const BLINKET_APP_URL = (process.env.REACT_APP_BLINKET_APP_URL || '').trim();
export const BLINKET_TICKETS_APP_URL = (process.env.REACT_APP_BLINKET_TICKETS_APP_URL || '').trim();

export const ACCOUNT_PARTNER = 'b1b17e0c-3438-4316-bb6d-283c9ff622c1';
export const ACCOUNT_ENV = process.env.REACT_APP_ENV === 'production' ? {} : { env: 'qa' };

export const ORBITA_URL = (process.env.REACT_APP_ORBITA_URL || '').trim();
export const VERTICAL_URL = (process.env.REACT_APP_VERTICAL_URL || '').trim();

export const WEBHOOK_URL = (process.env.REACT_APP_WEBHOOK_URL || '').trim();

export const HYPERFLOW_CHAT_UNITY_ID = process.env.REACT_APP_HYPERFLOW_CHAT_UNITY_ID ?? '';
export const HYPERFLOW_CHAT_BLACK_ID = process.env.REACT_APP_HYPERFLOW_CHAT_BLACK_ID ?? '';
export const HYPERFLOW_CHAT_ELITE_ID = process.env.REACT_APP_HYPERFLOW_CHAT_ELITE_ID ?? '';
