import { Menu, MenuItem } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { customBreakpoints: breakpoints, ...theme } }) => css`
    display: flex;
    flex-direction: column;

    .event-header-actions {
      margin: 12px 0 0 0;

      button {
        &:first-child {
          margin: 0 12px 0 0;
        }
      }
    }

    .event-header-infos {
      display: flex;

      img {
        border-radius: 4px;
        height: 57px;
        margin: 0 12px 0 0;
        width: 57px;
      }

      .event-header-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          color: ${theme.colors.primary.contrastText};
          font-size: 20px;
          font-weight: 600;
          line-height: 28.8px;
        }

        .event-header-date {
          display: flex;
          align-items: center;

          span {
            width: unset !important;
            height: unset !important;
            margin: 6px 4px 0 0;
          }

          svg,
          p {
            color: ${theme.colors.primary.contrastText};
            font-size: 14px;
            height: 16px;
            line-height: 21px;
          }

          .event-header-date-end {
            display: none;
          }
        }
      }
    }

    .event-header-actions {
      margin: 12px 0 0 0;

      button {
        &:first-child {
          margin: 0 12px 0 0;
        }
      }
    }

    @media (${breakpoints.md}) {
      flex-direction: row;
      justify-content: space-between;

      .event-header-infos {
        max-width: 65%;

        .event-header-description {
          .event-header-date {
            .event-header-date-end {
              margin: 0 0 0 5px;
              display: flex;
            }
          }
        }
      }
    }
  `}
`;

export const OptionMenu = styled(Menu)``;
export const OptionMenuItem = styled(MenuItem)`
  font-size: 14px;
  line-height: 21px;

  > span {
    margin: 0 10px 0 0;

    svg {
      color: #000000;
    }
  }
`;
