import React from 'react';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import styles from './styles';
import { IProps } from './types';

function CustomButton({
  id,
  classes,
  className,
  children,
  role,
  icon,
  endIcon,
  size,
  style,
  type,
  disabled,
  autoFocus,
  onClick
}: IProps) {
  if (!children && !!icon) {
    return (
      <IconButton
        id={id || ''}
        role={role}
        className={`${classes.root} ${className}`}
        style={style || null}
        onClick={onClick || null}
        type={type || 'button'}
        disabled={!!disabled}
        autoFocus={!!autoFocus}
        has-icon={true}
      >
        <span className={classes.iconContainer}>{icon}</span>
      </IconButton>
    );
  }

  return (
    <Button
      id={id || ''}
      role={role}
      className={`${classes.root} ${className}`}
      style={style || null}
      onClick={onClick || null}
      size={size || 'medium'}
      type={type || 'button'}
      disabled={!!disabled}
      autoFocus={!!autoFocus}
      has-icon={(!!icon).toString()}
      endIcon={endIcon || null}
    >
      {icon ? <span className={classes.iconContainer}>{icon}</span> : ''}
      {children}
    </Button>
  );
}

export default withStyles(styles)(CustomButton);
