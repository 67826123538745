import { AppStyle } from 'decorators/withStyles';
import { fontSizePxToRem, setPadding } from 'helpers/functions';

const styles: AppStyle = () => {
  return {
    root: {
      ...setPadding(9, 16, 9, 16),
      whiteSpace: 'nowrap',
      lineHeight: 1,
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 600,
      fontSize: fontSizePxToRem(14),
      '&[role=primary]': {
        backgroundColor: '#FFBC00',
        color: '#000000',
        '&:hover': { borderColor: '#000000' },
        '&:active': { borderColor: '#000000' },
        '&:disabled': {
          backgroundColor: '#BFBFBF',
          color: '#808080'
        }
      },
      '&[role=secondary]': {
        backgroundColor: 'transparent',
        color: '#000000',
        padding: '6.4px 16px',
        borderRadius: 0,
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        border: '1px solid #00000026',
        '&:hover': {
          color: '#00000',
          borderColor: '#00000'
        },
        '&:disabled': {
          backgroundColor: '#BFBFBF',
          color: '#808080'
        }
      },
      '&[role=secondaryLight]': {
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        border: 'thin solid #FFFFFF',
        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
        '&:active': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
        '&:disabled': {
          backgroundColor: '#BFBFBF',
          color: '#808080'
        }
      },
      '&[role=text]': {
        backgroundColor: 'transparent',
        color: '#00000',
        textTransform: 'none',
        fontWeight: 400,
        '&:hover': { color: '#000000' },
        '&:active': { color: '#00000' },
        '&:disabled': { color: '#808080' }
      },
      '&[role=textUnderline]': {
        backgroundColor: 'transparent',
        color: '#00000',
        textTransform: 'none',
        textDecoration: 'underline',
        fontWeight: 400,
        '&:hover': { color: '#000000' },
        '&:active': { color: '#00000' },
        '&:disabled': { color: '#808080' }
      },
      '&[role=textLight]': {
        backgroundColor: 'transparent',
        color: '#000000',
        textTransform: 'none',
        fontWeight: 400,
        '&:hover': { color: '#000000' },
        '&:active': { color: '#000000' },
        '&:disabled': { color: '#808080' }
      },
      '&[class*=sizeLarge]': {
        ...setPadding(4, 12, 4, 12),
        fontSize: fontSizePxToRem(16)
      },
      '&[class*=sizeSmall]': {
        ...setPadding(4, 12, 4, 12),
        fontSize: fontSizePxToRem(14),
        lineHeight: '150%'
      }
    }
  };
};

export default styles;
