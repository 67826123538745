import React, { PureComponent } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import InviteListPage from './List';

interface IProps {
  opened: boolean;
  data: any;
  onCancel: () => void;
  onComplete: (inviteKey: string, type: string) => void;
  type?: string;
}

export default class ListInvites extends PureComponent<IProps> {
  render() {
    const { opened, onCancel, data, onComplete, type } = this.props;
    const itemData = data.trim().split(' ');
    return (
      <Dialog open={opened} disableBackdropClick disableEscapeKeyDown maxWidth='lg'>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Escolha o convite</div>
            <IconButton onClick={onCancel} style={{ color: '#000000' }} id='chooseticket-dialogclose-button'>
              <CloseOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <InviteListPage items={itemData} onComplete={onComplete} type={type} id='chooseticket-dialog-item' />
        </DialogContent>
      </Dialog>
    );
  }
}
