import React from 'react';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { fontSizePxToRem, setMargin, setPadding } from 'helpers/functions';
import Select, { components } from 'react-select';

import { IProps } from './types';

function SelectMultipleCheckbox({ data, labelSelectOne, onChange, value, id }: IProps) {
  const customStyles = {
    placeholder: () => ({
      color: 'black',
      ...setMargin(null, null, null, 5),
      ...setPadding(8.5, null, 8.5, null),
      fontSize: fontSizePxToRem(15)
    }),

    indicatorSeparator: () => ({
      display: 'none'
    }),

    dropdownIndicator: () => ({
      color: '#757575'
    }),

    control: (provided: any) => ({
      ...provided,
      borderColor: '#E0E2F8',
      '&:hover': {
        borderColor: '#E0E2F8'
      },
      cursor: 'pointer'
    })
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginRight: 7.7
          }}
        >
          <ArrowDropDown />
        </div>
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      styles={customStyles}
      isMulti
      components={{ DropdownIndicator }}
      value={value}
      options={data}
      placeholder={labelSelectOne}
      onChange={onChange}
      id={id}
    />
  );
}
export default SelectMultipleCheckbox;
