import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, IconButton, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

type WrapperDisable = {
  is_active?: boolean;
};

export const CustomizedTypography = styled(Typography)`
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
`;

export const CustomizedIconButton = styled(IconButton)`
  color: #000000;
`;

export const Wrapper = styled(Grid)<WrapperDisable>`
  ${({ is_active }) => css`
    filter: ${!!is_active ? 'unset' : 'opacity(0.4) grayscale(1)'};
    pointer-events: ${!!is_active ? 'unset' : 'none'};
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  grid-gap: 5px;
`;

export const CustomizedHelpOutlineIcon = styled(HelpOutlineIcon)`
  margin-top: -2px;
`;

export const ErrorMessage = styled.p`
  color: #d32f2f;
  font-size: 0.75rem;
  margin: 3px 14px 0 14px;
`;
