import React from 'react';

import CopyOutlined from '@ant-design/icons/CopyOutlined';
import { Tooltip, Typography } from 'antd';
import { Input } from 'components/Shared/Antd';
import { CustomButtonAntd } from 'components/Shared/CustomButtonAntd/style';
import { SvgIcon } from 'components/Shared/SvgIcon';

type SalesPageLinkProps = {
  saleLink: string;
  name: string;
  isOrbitPage?: boolean;
};

const SalePageLink = ({ saleLink, name, isOrbitPage }: SalesPageLinkProps) => {
  return (
    <div className='event-links-sales-page' id={isOrbitPage ? 'orbit-page-sale-link' : 'sales-page-link'}>
      <span className='event-links-title'>{name}</span>
      <div className='event-links-container-desk'>
        <Input
          name={`link-${name.replace(' ', '-')}`}
          className='event-links-text-field lot-links'
          size='small'
          style={{ backgroundColor: 'white', cursor: 'auto' }}
          prefix={
            <a target='_blank' href={saleLink} rel='noopener noreferrer' style={{ marginRight: '4px' }}>
              <SvgIcon name='openInNew' />
            </a>
          }
          value={saleLink}
        />
        <Tooltip title={<Typography style={{ color: 'white' }}>Copiado!</Typography>} trigger='click'>
          <CustomButtonAntd
            onClick={() => {
              navigator.clipboard.writeText(saleLink);
            }}
            icon={<CopyOutlined />}
            size='large'
          >
            Copiar
          </CustomButtonAntd>
        </Tooltip>
      </div>
    </div>
  );
};

export default SalePageLink;
