import * as React from 'react';

import Icon from '@material-ui/core/Icon';
import { Button } from 'antd';
import Toast from 'components/Shared/Toast';
import attendanceListService from 'services/attendanceList';

const CheckInButton = ({ itemId, checkInDate }) => {
  const [checked, setChecked] = React.useState(!!checkInDate);
  const [loading, setLoading] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const handleCheckinAttendances = (attendanceId: string, active: 0 | 1) => {
    setLoading(true);
    attendanceListService.checkIn(attendanceId, active).subscribe({
      next: () => {
        setChecked(checked => !checked);
        setLoading(false);
      },
      error: () => {
        setLoading(false);
        return Toast.error('Ocorreu um erro ao realizar o check-in. Tente novamente');
      }
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          transition: 'all 300ms',
          position: 'relative',
          width: '150px',
          alignItems: 'center'
        }}
      >
        {checked ? (
          <>
            <Button
              disabled={loading}
              onMouseLeave={() => setIsHovering(false)}
              onClick={e => {
                e.stopPropagation();
                handleCheckinAttendances(itemId, 0);
              }}
              key={'undo-checkin-btn'}
              id='undo-checkin-btn'
              type='primary'
              style={{
                position: 'absolute',
                color: '#000000',
                fontSize: '12px',
                fontWeight: 'bold',
                transition: 'all 300ms',
                visibility: isHovering ? 'visible' : 'hidden',
                opacity: isHovering ? 1 : 0
              }}
            >
              {!loading ? 'DESFAZER CHECK-IN' : 'DESFAZENDO CHECK-IN...'}
            </Button>

            <Icon
              onMouseEnter={() => setIsHovering(true)}
              style={{
                color: 'green',
                visibility: isHovering ? 'hidden' : 'visible',
                opacity: isHovering ? 0 : 1,
                position: 'absolute'
              }}
            >
              check_circle
            </Icon>
          </>
        ) : (
          <Button
            disabled={loading}
            onClick={e => {
              e.stopPropagation();
              handleCheckinAttendances(itemId, 1);
            }}
            key={'checkin-btn'}
            id='checkin-btn'
            style={{
              position: 'absolute',
              color: '#000000',
              borderColor: '#d9d9d9',
              fontSize: '12px',
              fontWeight: 'bold'
            }}
          >
            {!loading ? 'FAZER CHECK-IN' : 'FAZENDO CHECK-IN...'}
          </Button>
        )}
      </div>
    </>
  );
};

export default CheckInButton;
