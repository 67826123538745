import { initializeGTM } from 'helpers/tagManager';
import { HotjarUser, setHotjarUser } from 'hotjar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as Rx from 'rxjs';
import * as RxOp from 'rxjs-operators';
import apiService from 'services/api';
import logService from 'services/log';
import { ACCOUNT_ENV } from 'settings';

import { LoginAsEmail, SendHelpRequest, User } from './types';

export class UserService {
  private user$: BehaviorSubject<User>;
  private logout$: BehaviorSubject<() => void>;

  constructor() {
    this.user$ = new BehaviorSubject(null);
    this.logout$ = new BehaviorSubject(null);
  }

  public getUser(): Observable<User> {
    return this.user$.asObservable();
  }

  public setUser(user: User): Observable<User> {
    this.user$.next(user);
    const { cli_cod: id, user_email: email, user_name: name, belt, acpo_original_user_cli_cod } = user;

    logService.setUser(user);
    setHotjarUser({
      id,
      email,
      name,
      belt,
      isAccessControl: id === acpo_original_user_cli_cod
    } as HotjarUser);
    initializeGTM({
      user_id: user.cli_cod,
      belt: user.belt
    });

    return of(user);
  }

  public setLogoutUser(action: () => void): Observable<() => void> {
    this.logout$.next(action);
    return of(action);
  }

  public logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    (window as any).Eduzz.Accounts.logout({
      ...ACCOUNT_ENV,
      redirectTo: window.location.origin
    });
  }

  public setMeAsProducer(data: any): Rx.Observable<any> {
    return apiService.post('/user/set_as_producer', data);
  }

  public hasUsedTour(data: any): Rx.Observable<any> {
    return apiService.put('/user/tour', data);
  }

  public loginByEventHash(data: any): Rx.Observable<any> {
    return apiService.post('/auth/loginbyeventhash', data).pipe(RxOp.map(res => res.data[0]));
  }

  public loginAs(data: any): Rx.Observable<User> {
    return apiService.post('/user/login_as', data).pipe(RxOp.map(res => res.data[0]));
  }

  public sendHelp(helpData: SendHelpRequest): Rx.Observable<any> {
    const { eventId, ...data } = helpData;
    return apiService.post(`help_services/helpsend/${eventId}`, data);
  }

  public loginAsEmail(email: String): Rx.Observable<LoginAsEmail> {
    return apiService.post('/user/acpo_login_as', { email }).pipe(RxOp.map(res => res.data[0]));
  }
}

const userService = new UserService();
export default userService;
