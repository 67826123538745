import React, { useEffect, useMemo, useState } from 'react';

import { useCustomObservable } from 'hooks';
import userService from 'services/user/index';
import { User } from 'services/user/types';
import { HYPERFLOW_CHAT_BLACK_ID, HYPERFLOW_CHAT_ELITE_ID, HYPERFLOW_CHAT_UNITY_ID } from 'settings';

type HyperflowParams = {
  id: number;
  name: string;
  email: string;
  eliteChatToken: string;
  original_id?: number;
  original_name?: string;
  original_email?: string;
};

function SupportChat() {
  const { value: user } = useCustomObservable<User, string, string>(() => userService.getUser(), null);
  const originalUser = useMemo(
    () => ({
      id: user?.acpo_original_user_cli_cod ?? null,
      email: user?.acpo_original_user_email ?? '',
      name: user?.acpo_original_user_name ?? ''
    }),
    [user?.acpo_original_user_cli_cod, user?.acpo_original_user_email, user?.acpo_original_user_name]
  );

  const [chatInitialized, setChatInitialized] = useState(false);
  const hasScript = !!document.getElementById('hf_script');

  function loadScript() {
    const scriptImport = document.createElement('script');
    scriptImport.type = 'text/javascript';
    scriptImport.async = true;
    scriptImport.id = 'hf_script';
    scriptImport.src = 'https://webchat.hyperflow.global/sdk.js';

    document.head.appendChild(scriptImport);
  }
  if (!hasScript) loadScript();

  function getChatTokenID() {
    const chatUnityID = HYPERFLOW_CHAT_UNITY_ID;
    const chatBlackID = HYPERFLOW_CHAT_BLACK_ID;
    const chatEliteID = HYPERFLOW_CHAT_ELITE_ID;
    const beltsCanViewChatBlack = ['Black', 'Golden', 'Sensei'];

    const beltUserCanViewChatBlack = beltsCanViewChatBlack.includes((user.belt || '').split(' ')[0]);

    if (user.is_clube_black) return chatUnityID;

    if (beltUserCanViewChatBlack) return chatBlackID;

    return chatEliteID;
  }

  useEffect(() => {
    if (!user || user.belt_id !== 0) return;

    if (typeof Hyperflow !== 'undefined' && !chatInitialized) {
      setChatInitialized(true);
      Hyperflow.init(getChatTokenID()).on('getStarted', () => {
        const params: HyperflowParams = {
          id: user.cli_cod,
          name: user.user_name.split(' ')[0],
          email: user.user_email,
          eliteChatToken: getChatTokenID()
        };

        if (user.cli_cod !== originalUser.id) {
          params.original_id = originalUser.id;
          params.original_name = originalUser.name.split(' ')[0];
          params.original_email = originalUser.email;
        }

        Hyperflow.initFlow('faknvewds', params);
      });
    }
  }, [user, originalUser, chatInitialized]);

  return <></>;
}

export default SupportChat;
