import React from 'react';

import PageTitle from 'Pages/MyEvents/components/PageTitle';
import { useParams } from 'react-router-dom';

import SaleLink from './SaleLink';
import SalePageLink from './SalePageLink';
import * as S from './styles';
import { SaleLinksProps } from './types';

const prefixLink = process.env.REACT_APP_BLINKET_EVENT_URL;

const SaleLinks = ({ tickets, saleLink, orbitPagesUrl, openTour }: SaleLinksProps) => {
  const [realSaleLink, setRealSaleLink] = React.useState(saleLink);

  const validatedUrl = prefixLink + '/' + realSaleLink;

  React.useEffect(() => {
    const updateLink = event => {
      if (event.type === 'inform-new-sale-link') {
        setRealSaleLink(event.detail);
      }
    };
    document.addEventListener('inform-new-sale-link', updateLink);
    return () => document.removeEventListener('inform-new-sale-link', updateLink);
  }, []);

  const { id } = useParams<{ id: string }>();

  const eventHasSalePage = id !== realSaleLink;

  return (
    <S.Container>
      <PageTitle
        title='Links de divulgação'
        subtitle='Links de checkout e página de vendas deste evento'
        onIconClick={openTour}
      />
      <div className='event-links-edit-container'>
        {eventHasSalePage && <SalePageLink name='URL da página do evento' saleLink={validatedUrl} />}
        {orbitPagesUrl && <SalePageLink name='Página de vendas Orbit Pages' saleLink={orbitPagesUrl} isOrbitPage />}
        {tickets?.map(ticket => (
          <React.Fragment key={ticket.id}>
            {ticket.cnt_cod === null ? null : (
              <SaleLink
                key={ticket.cnt_cod}
                name={ticket.name}
                cod={!!ticket.cnt_hash ? ticket.cnt_hash : ticket.cnt_cod}
                isTicket
              />
            )}
            {ticket.lot.map(item => (
              <SaleLink
                key={item.cnt_cod}
                name={`${ticket.name} - ${item.name}`}
                cod={!!item.cnt_hash ? item.cnt_hash : item.cnt_cod}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </S.Container>
  );
};

export default SaleLinks;
