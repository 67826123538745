import React, { Reducer, useCallback, useEffect, useMemo, memo, useReducer } from 'react';

import WarningOutlined from '@ant-design/icons/WarningOutlined';
import DraftPreview from 'components/Pages/Admin/MyEventsTabs/EventPage/Form/DraftPreview';
import { Typography } from 'components/Shared/Antd';
import CustomLoader from 'components/Shared/CustomLoader';
import Toast from 'components/Shared/Toast';
import { useCustomObservable } from 'hooks';
import { IEventPageEdit } from 'interfaces/event';
import ReactPlaceholder from 'react-placeholder/lib';
import { useParams, useHistory } from 'react-router-dom';
import { titlePageService } from 'services/routes';

import { PageParams } from '../BasicData/types';
import { Form } from './components';
import FormContext from './context';
import customTemplateService from './services';
import * as S from './styles';
import { CustomTemplateData, StateProps, ActionProps, ActionTypes } from './types';

const initialState: StateProps = {
  hasChangedData: false,
  openPreview: false,
  isSaving: false,
  draftSaved: false
};

const reducer: Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case ActionTypes.hasChangedData:
      return { ...state, hasChangedData: action.value as boolean };

    case ActionTypes.openPreview:
      return { ...state, openPreview: action.value as boolean };

    case ActionTypes.isSaving:
      return { ...state, isSaving: action.value as boolean };

    case ActionTypes.draftSaved:
      return { ...state, draftSaved: true, isSaving: false };

    case ActionTypes.hasSavedData:
      return { ...state, hasChangedData: false, isSaving: false };

    default:
      return state;
  }
};

const CustomTemplate = memo(() => {
  const [{ hasChangedData, openPreview, isSaving, draftSaved }, dispatch] = useReducer(reducer, {
    ...initialState
  });

  const { id: eventId } = useParams<PageParams>();
  const history = useHistory();

  const { value: customTemplateData, loading } = useCustomObservable<CustomTemplateData, string, string>(
    () => customTemplateService.get(eventId),
    eventId
  );

  useEffect(() => {
    titlePageService.set(
      ['Meus Eventos'],
      [
        { label: 'Meus Eventos', path: '/my-events' },
        { label: customTemplateData?.eventHeader.title, path: `/my-events/${eventId}/basic-data` },
        { label: 'Página do evento', path: `/my-events/${eventId}/event-page` },
        { label: 'Customizar template', path: '' }
      ],
      { label: 'Página do evento', path: `/my-events/${eventId}/event-page` }
    );
  }, [customTemplateData, eventId]);

  const isShown = useMemo(() => !loading && !!customTemplateData, [loading, customTemplateData]);

  const handleVerifyData = useCallback(() => {
    if (!hasChangedData) {
      dispatch({ type: ActionTypes.hasChangedData, value: true });
    }
  }, [hasChangedData]);

  const handleOpenPreview = useCallback((open: boolean) => {
    dispatch({ type: ActionTypes.openPreview, value: open });
  }, []);

  const handleShowWarnigDraft = useCallback(
    (isDraft: boolean) => {
      dispatch({ type: ActionTypes.draftSaved, value: isDraft });
    },
    [dispatch]
  );

  const handleSaveEdit = useCallback(
    (data: IEventPageEdit, isDraft: boolean) => {
      const templateVersion = data.template_type === 'basic' ? 'v1' : 'v2';
      const page_config_form_data = { ...customTemplateData.pageContent.page_config, ...data, templateVersion };

      const payload = {
        ...(data?.pixel_key && { pixel_key: data.pixel_key }),
        sale_url: data.sale_url,
        template_type: data.template_type,
        description: customTemplateData.pageContent.description,
        draft_description: customTemplateData.pageContent.draft_description,
        ...(isDraft
          ? { draft_page_config: page_config_form_data }
          : { page_config: page_config_form_data, draft_page_config: page_config_form_data })
      };

      dispatch({ type: ActionTypes.isSaving, value: true });

      customTemplateService.put(eventId, payload).subscribe({
        next: () => {
          if (!isDraft) {
            Toast.show('Salvo com sucesso');
            history.push(`/my-events/${eventId}/sale-links`);
            window['newSaleUrl'] = data.sale_url;
            const notifyNewSaleUrl = new CustomEvent('inform-new-sale-link', { detail: data.sale_url });
            document.dispatchEvent(notifyNewSaleUrl);

            return dispatch({ type: ActionTypes.hasSavedData });
          }

          Toast.show('Rascunho salvo com sucesso');
          dispatch({ type: ActionTypes.draftSaved });
          handleShowWarnigDraft(isDraft);
        },
        error: ({ data: response }) => {
          if (response && response?.code === 'ERR_CUSTOM') {
            Toast.error(response.details);
          } else {
            Toast.error('Erro ao salvar');
          }
          dispatch({ type: ActionTypes.isSaving, value: false });
          handleShowWarnigDraft(isDraft);
        }
      });
    },
    [customTemplateData?.pageContent, eventId, handleShowWarnigDraft, history]
  );

  return (
    <S.Container>
      <ReactPlaceholder customPlaceholder={<CustomLoader />} ready={!loading}>
        {isShown && (
          <>
            <Typography.Title level={4}>Customizar template</Typography.Title>
            <div>
              {draftSaved && (
                <div className='custom-template-warning-draft'>
                  <h2>
                    <WarningOutlined /> Atenção
                  </h2>
                  <p>Você salvou algumas informações como “rascunho”, para publicá-las clique em “salvar”.</p>
                </div>
              )}
              <FormContext.Provider
                value={{
                  customTemplateData,
                  handleVerifyData,
                  handleOpenPreview,
                  handleSaveEdit,
                  isSaving,
                  hasChangedData,
                  draftSaved,
                  eventId
                }}
              >
                <Form />
              </FormContext.Provider>
              <DraftPreview
                open={openPreview}
                saleUrl={customTemplateData.pageContent.sale_url}
                onClose={() => handleOpenPreview(false)}
              />
            </div>
          </>
        )}
      </ReactPlaceholder>
    </S.Container>
  );
});

export default CustomTemplate;
