import { hotjar } from 'react-hotjar';

const HOTJAR_ID = 1004096;
const HOTJAR_SNIPPET_VERSION = 6;

export enum HotjarEvents {
  SATISFACTION_SURVEY = 'satisfaction_survey'
}

export function submitEvent(event: HotjarEvents) {
  hotjar.event(event);
}

export type HotjarUser = {
  id: number;
  email: string;
  name: string;
  isAccessControl: boolean;
  belt?: string;
};

export function setHotjarUser({ id, ...user }: HotjarUser) {
  hotjar.identify(String(id), user);
}

function main() {
  hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
}

main();
