import PlaceIcon from '@mui/icons-material/Place';
import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div``;

export const CustomizedPlaceIcon = styled(PlaceIcon)`
  color: black;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 240px;
`;

export const Title = styled.h6`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 10px;

  ${media.greaterThan('medium')`
    font-size: 1.25rem;
  `}
`;

export const Subtitle = styled.h6`
  color: #595959;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 10px;
  ${media.greaterThan('medium')`
  font-size: 1rem;
`}
`;
