import { Onboarding } from 'interfaces/onboarding';
import * as Rx from 'rxjs';
import * as RxOp from 'rxjs-operators';

import apiService, { ApiService } from './api';

export class OnboardingService {
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  public detail(): Rx.Observable<Onboarding> {
    return this.apiService.get('/onboarding/details').pipe(RxOp.map(response => response.data[0]));
  }

  public updateOnboardingSteps(params?: any): Rx.Observable<any> {
    return this.apiService.post('/onboarding/edit', params).pipe(RxOp.map(response => response.data[0]));
  }
}

const onboardingService = new OnboardingService(apiService);
export default onboardingService;
