/* eslint-disable max-lines */
import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import PageLoader from 'components/Shared/PageLoader';
import Toast from 'components/Shared/Toast';
import Tour from 'components/Shared/Tour';
import { getTargetElement } from 'components/Shared/Tour/helpers';
import { TourSteps } from 'components/Shared/Tour/types';
import { WithRouter } from 'decorators/withRouter';
import { WithStyles } from 'decorators/withStyles';
import { hjTagging } from 'helpers/functions';
import { IAttendaceList, IEditPresence } from 'interfaces/attendanceList';
import RxOp from 'rxjs-operators';
import attendanceListService from 'services/attendanceList';
import { NewAttendanceList } from 'services/attendanceList/types';
import eventService from 'services/event';
import { titlePageService } from 'services/routes';
import userService from 'services/user';

import FilterOptions from './FilterOptions';
import HeaderList from './HeaderList';
import ModalDialogs from './ModalDialogs';
import PresenceList from './PresenceList';
import SelectAllPagesItems from './SelectAllPagesItems';
import styles from './styles';
import { IProps, IState } from './types';

@WithStyles(styles)
@WithRouter()
export default class AttendanceList extends PureComponent<IProps, IState> {
  readonly state: IState = {
    isOpenResendTicket: false,
    isOpenModalSendEmailToParticipants: false,
    emailToParticipantesInviteKeysSelected: [],
    isOpenAttendanceDetail: false,
    isOpenAttendanceList: true,
    tags: [],
    isOpenEdit: false,
    isOpenEditMultiple: false,
    isOpenMarkerMultiple: false,
    isOpenModalLink: false,
    isOpenLabelManager: false,
    model: null,
    inviteMultipleData: null,
    userEmail: null,
    client: null,
    participantsToPrint: [],
    loading: false,
    events: [],
    pagination: {
      page: 1,
      totalPages: 1,
      size: 0,
      totalRows: 0
    },
    filter: {},
    hasAppliedAdvancedFilter: false,
    eventDetail: {},
    data: [],
    eventFilterSelected: {} as any,
    eventIdSelected: null,
    hasAttendanceChanges: false,
    user: null,
    noTickets: false,
    selectedParticipants: [],
    allPagesParticipantsAreSelected: false,
    isMultipleAction: false,
    isEventLiveOnline: null,
    generateTagsModel: null,
    isLoadingGenerateTagsModel: true
  };

  toolbarComponent: any = React.createRef();

  componentDidMount() {
    hjTagging('page:presencelist-detail');
    this.loadData({ event_id: this.props.match.params.id });
    this.loadTags();
    this.loadUser();
    window.scrollTo({ top: 0 });
  }
  onError = (errorMessage: string, hasState: boolean) => {
    if (errorMessage === 'Você não possui permissão para realizar esta ação') {
      Toast.error(errorMessage, 6000);
    }
    if (hasState) {
      this.setState({
        loading: false,
        data: []
      });
    }
  };

  loadUser = () => {
    userService
      .getUser()
      .pipe(RxOp.logError(), RxOp.bindComponent(this))
      .subscribe({
        next: userProfile => this.setState({ user: userProfile }),
        error: ({ data: err }) => {
          this.onError(err.message, false);
        }
      });
  };

  loadEventDetail = () => {
    const eventId = this.props.match.params.id;

    eventService
      .detail(eventId)
      .pipe(RxOp.bindComponent(this), RxOp.logError())
      .subscribe({
        next: newEventDetail => {
          titlePageService.set(['Participantes', `${newEventDetail.title}~${eventId}`]);
          const isEventLiveOnline = newEventDetail.type === 'online' && newEventDetail.is_live === 1;
          this.setState({
            eventDetail: newEventDetail,
            isEventLiveOnline
          });
        },
        error: ({ data: err }) => {
          this.onError(err.message, false);
        }
      });
  };

  loadTags = () => {
    attendanceListService
      .listTags()
      .pipe(RxOp.bindComponent(this), RxOp.logError())
      .subscribe({
        next: tags => this.setState({ tags }),
        error: ({ data: err }) => {
          this.onError(err.message, false);
        }
      });
  };

  refreshData = () => this.submitFilter();

  getFilter = (params: any) => {
    const { search, ...rest } = params;
    const filter = { ...rest };

    filter.event_id = this.props.match.params.id;

    if (filter.status && rest.status === 'all') {
      delete filter.status;
    }

    if (search) {
      filter['search'] = search;
    }

    if (filter.name) {
      filter.name = '%' + filter.name + '%';
    }

    return filter;
  };

  loadDataSubmit = (filter: any) => {
    attendanceListService
      .list(filter)
      .pipe(RxOp.bindComponent(this), RxOp.logError())
      .subscribe({
        next: ({ data, ...pagination }) => {
          const newData = this.convertNewAttendanceList(data);
          if (this.state.allPagesParticipantsAreSelected) {
            this.setState({ selectedParticipants: newData });
          }
          this.setState(
            {
              loading: false,
              data: newData,
              pagination: {
                page: pagination.current_page,
                totalPages: pagination.last_page,
                size: pagination.per_page,
                totalRows: pagination.total
              }
            },
            () => this.loadEventDetail()
          );
        },
        error: ({ data: err }) => {
          let errorMessage = err?.message;
          if (errorMessage === 'Você não possui permissão para realizar esta ação') {
            errorMessage = 'Você não possui permissão para visualizar os participantes';
          }
          this.onError(errorMessage, true);
        }
      });
  };

  loadData = (params: any) => {
    const filter = this.getFilter(params);

    this.setState(
      {
        loading: true,
        data: [],
        filter
      },
      () => {
        this.loadDataSubmit(filter);
      }
    );
  };

  loadDataPaginate = (page: number, pageSize: number = this.state.pagination.size) => {
    const filter = { ...this.state.filter };

    filter.page = page;
    filter.pagesize = pageSize;

    this.setState(
      {
        loading: true,
        data: []
      },
      () => {
        this.loadDataSubmit(filter);
      }
    );
    this.loadEventDetail();
  };

  loadGenerateTagsModel = () => {
    this.setState({
      isLoadingGenerateTagsModel: true
    });
    return new Promise(resolve => {
      attendanceListService
        .getGenerateTagsModel()
        .pipe(RxOp.bindComponent(this), RxOp.logError())
        .subscribe({
          next: model => {
            const { data: generateTagsModel } = model;
            this.setState({
              isLoadingGenerateTagsModel: false,
              generateTagsModel
            });
            resolve(true);
          },
          error: () => {
            this.setState({
              isLoadingGenerateTagsModel: false,
              generateTagsModel: null
            });
            resolve(true);
          }
        });
    });
  };

  convertNewAttendanceList = (data: NewAttendanceList[]): IAttendaceList[] => {
    const newData: IAttendaceList[] = data?.map(({ attendances, total }) => {
      const [attendance] = attendances;

      attendance.custom_fields =
        typeof attendance.custom_fields == 'string'
          ? JSON.parse(attendance.custom_fields)
          : attendance.custom_fields ?? {};

      return { ...attendance, total };
    });

    return newData;
  };

  handleOpenModalLabelManager = async (isOpenLabelManager: boolean, participantsToPrint: IAttendaceList[] = []) => {
    if (isOpenLabelManager) {
      this.setState({
        isOpenLabelManager,
        participantsToPrint,
        isLoadingGenerateTagsModel: true
      });
      await this.loadGenerateTagsModel();
      this.setState({
        isLoadingGenerateTagsModel: false
      });
    }
  };

  handleOpenModalLink = (isOpenModalLink: boolean, client: IAttendaceList = null, isMultipleAction = false) => {
    const itemData = client?.attendance_list_ids ?? [];

    if (itemData.length > 1) {
      return this.setState({
        client,
        isOpenMarkerMultiple: isOpenModalLink,
        inviteMultipleData: { id: client.invite_key },
        isMultipleAction
      });
    }

    return this.setState({ isOpenModalLink, client, isMultipleAction });
  };

  handleOpenModalEdit = (isOpenEdit: boolean, model: IEditPresence = null) => {
    if (!isOpenEdit) {
      this.handleUpdateAttendanceDetail();
    }

    const itemData = model ? model.id.trim().split(' ') : [];

    if (itemData.length > 1) {
      return this.setState({
        model,
        isOpenEditMultiple: isOpenEdit,
        inviteMultipleData: {
          id: model.id
        }
      });
    }

    return this.setState({ isOpenEdit, model });
  };

  handleOpenModalResendTicket = (isOpenResendTicket: boolean, userEmail: string = null, isMultipleAction = false) => {
    if (isOpenResendTicket && isMultipleAction) {
      this.setState({ isOpenResendTicket, isMultipleAction });
      return;
    }

    return this.setState({
      isOpenResendTicket,
      userEmail,
      isMultipleAction: false
    });
  };

  handlePaginationChange = (pageSize: number) => this.loadDataPaginate(1, pageSize);

  handleCheckinAttendances = (attendanceId: string, active: 0 | 1, callback?: any): any => {
    attendanceListService.checkIn(attendanceId, active).subscribe({
      next: () => {
        callback && callback();
        this.refreshData();
        this.forceUpdate();
      },
      error: ({ data: err }) => {
        this.onError(err.message, false);

        return Toast.error('Ocorreu um erro ao realizar o check-in. Tente novamente');
      }
    });
  };

  handleUpdateAttendanceDetail = () => {
    const { cliCodSelected, isOpenAttendanceDetail, handleCloseAttendanceDetail, handleOpenAttendanceDetail } =
      this.props;

    if (isOpenAttendanceDetail && cliCodSelected) {
      handleCloseAttendanceDetail();
      handleOpenAttendanceDetail(cliCodSelected);
    }
  };

  addedParticipant = () => {
    const { page, size } = this.state.pagination;

    this.loadDataPaginate(page, size);
    this.loadEventDetail();
  };

  handleOpenModalSendEmailToParticipants = (actionData: any) => {
    let inviteKeysSelected: string[];

    switch (actionData) {
      case 'toAll':
        inviteKeysSelected = [];
        break;
      case 'toSelected':
        inviteKeysSelected = this.emailToParticipantesGetInviteKeysSelected();
        break;
      default:
        inviteKeysSelected = [actionData];
        break;
    }

    this.setState({
      isOpenModalSendEmailToParticipants: true,
      emailToParticipantesInviteKeysSelected: inviteKeysSelected
    });
  };

  emailToParticipantesGetInviteKeysSelected = () => {
    const inviteKeys: string[] = [];

    this.state.selectedParticipants.map((item: any) => {
      const inviteKey = item.invite_key.split(' ');

      inviteKey.map((invite: any) => {
        !!invite.length && inviteKeys.push(invite);
        return invite;
      });

      return item;
    });

    return inviteKeys;
  };

  getStateFilter = (stateFilter: any) => {
    if (stateFilter.tags === 'all') {
      delete stateFilter.tags;
    }
    if (stateFilter.ticket_id === 'all') {
      delete stateFilter.ticket_id;
    }
    if (stateFilter.status === 'all') {
      delete stateFilter.status;
    }
    if (stateFilter.attendance_situation === 'all') {
      delete stateFilter.attendance_situation;
    }
    stateFilter.event_id = this.state.eventIdSelected || this.props.match.params.id;
    return stateFilter;
  };

  submitFilter = () => {
    this.setState({ selectedParticipants: [], allPagesParticipantsAreSelected: false });
    const stateFilter = this.getStateFilter(this.state.filter);
    const filterHasParameters =
      !!stateFilter['attendance_situation'] || !!stateFilter.tags || !!stateFilter.tickets || !!stateFilter.status;
    this.setState({ hasAppliedAdvancedFilter: filterHasParameters });
    this.loadData({ ...stateFilter });
  };

  handleUpdateState = (newState: object, callback?: () => void) => this.setState(newState, callback || null);

  handleSelectAllPagesParticipants = () => {
    const { allPagesParticipantsAreSelected } = this.state;

    this.setState({
      allPagesParticipantsAreSelected: !allPagesParticipantsAreSelected,
      selectedParticipants: allPagesParticipantsAreSelected ? [] : this.state.selectedParticipants
    });
  };

  render(): React.ReactNode {
    const {
      classes,
      match,
      isOpenAttendanceDetail,
      isOpenAttendanceList,
      handleCloseAttendanceDetail,
      handleOpenAttendanceDetail,
      cliCodSelected,
      showAttendanceListTour,
      handleOpenAttendanceListTour,
      handleCloseAttendanceListTour
    } = this.props;

    const {
      data,
      loading,
      isOpenModalSendEmailToParticipants,
      emailToParticipantesInviteKeysSelected,
      isOpenResendTicket,
      isOpenLabelManager,
      participantsToPrint,
      userEmail,
      isOpenEdit,
      isOpenEditMultiple,
      isOpenMarkerMultiple,
      model,
      tags,
      eventDetail,
      isOpenModalLink,
      client,
      pagination,
      eventIdSelected,
      inviteMultipleData,
      user,
      filter,
      selectedParticipants,
      allPagesParticipantsAreSelected,
      isMultipleAction,
      isEventLiveOnline,
      hasAppliedAdvancedFilter,
      generateTagsModel,
      isLoadingGenerateTagsModel
    } = this.state;

    const selectedParticipantsIds = selectedParticipants.map(participant => participant.id);
    const allParticipantsOfPageAreSelected = data.every(item => selectedParticipantsIds.includes(item.id));

    const steps: TourSteps[] = [
      {
        title: 'Lista de participantes',
        description:
          ' Adicione participantes em sua lista para evitar problemas durante o credenciamento. Você pode inserir convidados, palestrantes, imprensa, freelancers, etc.',
        target: getTargetElement('button#add-participant-btn'),
        placement: 'bottomRight'
      },
      {
        title: 'Lista de participantes',
        description: 'Analise e gerencie sua lista de participantes através dos filtros.',
        target: getTargetElement('button#advanced-filter-btn'),
        placement: 'bottomRight'
      },
      {
        title: 'Lista de participantes',
        description: 'Tenha um credenciamento rápido e prático. Aqui o check-in é feito com somente um clique.',
        target: getTargetElement('button#checkin-btn'),
        isLastStep: true
      }
    ];

    return (
      <>
        {!isOpenAttendanceList && !isOpenAttendanceDetail && <PageLoader />}
        {!isOpenAttendanceDetail && (
          <Grid container className={classes.parentGrid}>
            <Grid item md={12} xs={12}>
              {!!eventDetail && !!user && (
                <HeaderList
                  attendanceList={data}
                  eventDetail={eventDetail}
                  user={user}
                  filter={filter}
                  tags={tags}
                  handleUpdateTags={this.handleUpdateState}
                  loadTags={this.loadTags}
                  addedParticipant={this.addedParticipant}
                  handleOpenModalSendEmailToParticipants={this.handleOpenModalSendEmailToParticipants}
                  handleOpenAttendanceListTour={handleOpenAttendanceListTour}
                />
              )}

              <FilterOptions
                attendanceItems={data}
                filter={filter}
                tags={tags}
                user={user}
                eventDetail={eventDetail}
                eventIdSelected={eventIdSelected || match.params.id}
                selectedParticipants={selectedParticipants}
                disableLabelManager={allPagesParticipantsAreSelected && pagination.totalRows > 400}
                refreshData={this.refreshData}
                loadData={this.loadData}
                handleSubmitFilter={this.submitFilter}
                handleUpdateParentState={this.handleUpdateState}
                handleOpenModalLink={this.handleOpenModalLink}
                handleOpenModalResendTicket={this.handleOpenModalResendTicket}
                handleCheckinAttendances={this.handleCheckinAttendances}
                handleOpenModalLabelManager={this.handleOpenModalLabelManager}
                handleOpenModalSendEmailToParticipants={this.handleOpenModalSendEmailToParticipants}
              />
              <SelectAllPagesItems
                allowAction={
                  !hasAppliedAdvancedFilter &&
                  pagination.totalRows > selectedParticipants.length &&
                  allParticipantsOfPageAreSelected
                }
                isSelected={allPagesParticipantsAreSelected}
                selectedItems={allPagesParticipantsAreSelected ? pagination.totalRows : selectedParticipants.length}
                onClick={this.handleSelectAllPagesParticipants}
              />
              {match.params.id && (
                <PresenceList
                  items={data}
                  loading={loading}
                  pagination={pagination}
                  loadData={this.loadDataPaginate}
                  handleOpenModalLink={this.handleOpenModalLink}
                  handleOpenModalResendTicket={this.handleOpenModalResendTicket}
                  handleOpenModalEdit={this.handleOpenModalEdit}
                  handleOpenModalLabelManager={this.handleOpenModalLabelManager}
                  handlePaginationChange={this.handlePaginationChange}
                  handleOpenAttendanceDetail={handleOpenAttendanceDetail}
                  handleCheckinAttendances={this.handleCheckinAttendances}
                  handleUpdateParentState={this.handleUpdateState}
                  handleOpenModalSendEmailToParticipants={false ? this.handleOpenModalSendEmailToParticipants : null}
                  selectedParticipants={selectedParticipants}
                  isEventLiveOnline={isEventLiveOnline}
                  allParticipantsOfPageAreSelected={allParticipantsOfPageAreSelected}
                  attendanceItems={data}
                  allPagesParticipantsAreSelected={allPagesParticipantsAreSelected}
                />
              )}
              {eventDetail?.tickets?.[0]?.id && (
                <Tour steps={steps} open={showAttendanceListTour} onClose={handleCloseAttendanceListTour} />
              )}
            </Grid>
          </Grid>
        )}

        <ModalDialogs
          data={data}
          tags={tags}
          client={client}
          userEmail={userEmail}
          eventId={match.params.id}
          eventDetail={eventDetail}
          cliCodSelected={cliCodSelected}
          eventIdSelected={eventIdSelected || match.params.id}
          isEventLiveOnline={isEventLiveOnline}
          selectedParticipants={selectedParticipants}
          emailToParticipantesInviteKeysSelected={emailToParticipantesInviteKeysSelected}
          model={model}
          participantsToPrint={participantsToPrint}
          pagination={pagination}
          inviteMultipleData={inviteMultipleData}
          isMultipleAction={isMultipleAction}
          isOpenModalLink={isOpenModalLink}
          isOpenResendTicket={isOpenResendTicket}
          allParticipantsAreSelected={allPagesParticipantsAreSelected}
          isOpenModalSendEmailToParticipants={isOpenModalSendEmailToParticipants}
          isOpenEdit={isOpenEdit}
          isOpenEditMultiple={isOpenEditMultiple}
          isOpenMarkerMultiple={isOpenMarkerMultiple}
          isOpenLabelManager={isOpenLabelManager}
          isOpenAttendanceDetail={isOpenAttendanceDetail}
          isLoadingGenerateTagsModel={isLoadingGenerateTagsModel}
          generateTagsModel={generateTagsModel}
          refreshData={this.refreshData}
          handleUpdateParentState={this.handleUpdateState}
          handleOpenModalLink={this.handleOpenModalLink}
          handleOpenModalResendTicket={this.handleOpenModalResendTicket}
          handleOpenModalEdit={this.handleOpenModalEdit}
          handleOpenModalLabelManager={this.handleOpenModalLabelManager}
          handleUpdateAttendanceDetail={this.handleUpdateAttendanceDetail}
          loadDataPaginate={this.loadDataPaginate}
          handleCloseAttendanceDetail={handleCloseAttendanceDetail}
          handleOpenAttendanceDetail={handleOpenAttendanceDetail}
          handleOpenModalSendEmailToParticipants={this.handleOpenModalSendEmailToParticipants}
          handleCheckinAttendances={this.handleCheckinAttendances}
        />
      </>
    );
  }
}
