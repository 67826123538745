import React from 'react';

import { Button, Paper, withStyles } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { ORBITA_EDIT_PRODUCT_PATH_URL } from 'helpers/constants';
import { formatUrlWithUtm } from 'helpers/functions';
import { useHistory } from 'react-router-dom';
import { ORBITA_URL } from 'settings';

import styles from './styles';
import { IProps } from './types';

const HintsCard = ({ classes, event }: IProps) => {
  const cnt_cod = event?.tickets[0]?.lot[0]?.cnt_cod;
  const history = useHistory();

  const handleLostSales = () => {
    const url = `${ORBITA_EDIT_PRODUCT_PATH_URL}${cnt_cod}`;
    window.open(formatUrlWithUtm(url, 'blinket', 'dashboard'));
  };
  const handleLeads = () => {
    const url = `${ORBITA_URL}/producer/leads`;
    window.open(formatUrlWithUtm(url, 'blinket', 'dashboard'));
  };
  const handleManageTickets = () => {
    sessionStorage.setItem('step', '3');
    history.push(`/event/edit/${event.id}`);
  };

  return (
    <Paper className={classes.root} elevation={1} variant='outlined' square>
      <h5 className={classes.title}>
        Você pode <strong>aumentar suas vendas</strong>
      </h5>

      <p className={classes.description}>Separamos algumas ações que podem te ajudar a aumentar suas vendas</p>

      <div className={classes.listActions}>
        <Button variant='outlined' endIcon={<ArrowForward />} onClick={handleLostSales}>
          Ativar a recuperação de vendas perdidas
        </Button>

        {!event.is_live && (
          <Button variant='outlined' endIcon={<ArrowForward />} onClick={handleManageTickets}>
            Customizar a estratégia de lotes do seu evento
          </Button>
        )}

        <Button variant='outlined' endIcon={<ArrowForward />} onClick={handleLeads}>
          Trabalhar seus leads que não compraram
        </Button>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(HintsCard);
