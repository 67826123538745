import React from 'react';

import CopyOutlined from '@ant-design/icons/CopyOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { Tooltip } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { Input } from 'components/Shared/Antd';
import { CustomButtonAntd } from 'components/Shared/CustomButtonAntd/style';
import { SvgIcon } from 'components/Shared/SvgIcon';

type SaleLinkProps = {
  cod: string | number;
  name: string;
  isTicket?: boolean;
};

const SaleLink = ({ cod, name, isTicket }: SaleLinkProps) => {
  const lotLink = `${process.env.REACT_APP_SUN_URL}/${cod}`;

  return (
    <div className='event-links-sales-page' key={cod} id={isTicket ? 'lot-link-auto-change' : 'lot-link'}>
      <span className='event-links-title'>
        {isTicket ? (
          <>
            {name} - Link para virar lote automaticamente{' '}
            <Tooltip
              title={
                <Typography style={{ color: 'white' }}>
                  Ao utilizar esse link a troca de lote expirado será efetuado automaticamente, para o próximo lote
                  disponível.
                </Typography>
              }
              placement='top'
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        ) : (
          <>{name}</>
        )}
      </span>
      <div className='event-links-container-desk'>
        <Input
          name={`link-${name.replace(' ', '-')}`}
          className='event-links-text-field lot-links'
          size='small'
          style={{ backgroundColor: 'white', cursor: 'auto' }}
          prefix={
            <a target='_blank' href={lotLink} rel='noopener noreferrer' style={{ marginRight: '4px' }}>
              <SvgIcon name='openInNew' />
            </a>
          }
          value={!cod || cod == '0' ? 'Criando link de divulgação...' : lotLink}
        />
        <Tooltip title={<Typography style={{ color: 'white' }}>Copiado!</Typography>} trigger='click'>
          <CustomButtonAntd
            onClick={() => {
              navigator.clipboard.writeText(lotLink);
            }}
            size='large'
            icon={<CopyOutlined />}
          >
            Copiar
          </CustomButtonAntd>
        </Tooltip>
      </div>
    </div>
  );
};

export default SaleLink;
