import React, { useState } from 'react';

import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Grid } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { PinIcon, AddIcon } from 'components/Shared/Icons';
import Toast from 'components/Shared/Toast';
import { sendEventToGA } from 'helpers/tagManager';
import { IEvent } from 'interfaces/event';
import { ITags } from 'interfaces/tags';
import PageTitle from 'Pages/MyEvents/components/PageTitle';
import attendanceListService from 'services/attendanceList';

import AddMethodDialog from './AddMethodDialog';
import ExportPdfDialog from './ExportPdf';
import MarkerEditDialog from './MarkerEditDialog';
import styles from './styles';
import { IProps } from './types';

type EventDetailContextType = {
  eventDetail: Partial<IEvent>;
  isOpen: boolean;
  openAddParticipantDialogTest: () => void;
  onClose: () => void;
  getCurrentTicketName: (selectedTicketId: string) => string;
  firstTicketAvailableStock: number;
  getAvailableStock: (selectedTicketId: string) => number;
  addedParticipant: () => void;
};

export const EventDetailContext = React.createContext<EventDetailContextType>({} as EventDetailContextType);

const HeaderList = ({
  attendanceList,
  eventDetail,
  filter,
  tags,
  loadTags,
  handleUpdateTags,
  addedParticipant,
  handleOpenAttendanceListTour
}: IProps) => {
  const [isOpenEditMarker, setIsOpenEditMarker] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [isOpenExportPdf, setIsOpenExportPdf] = useState(false);
  const [isOpenAddParticipantDialog, setIsOpenAddParticipantDialog] = useState(false);
  const [firstTicketAvailableStock, setFirstTicketAvailableStock] = useState(0);
  const buttonExportRef = React.useRef<HTMLButtonElement>(null);
  const [isOpenExportMenu, setIsOpenExportMenu] = useState(false);

  const getAvailableStock = (selectedTicketId: string) => {
    if (!selectedTicketId) return;
    const selectedTicket = eventDetail.tickets.filter(ticket => ticket.id === selectedTicketId);
    const selectedLot = selectedTicket.length ? selectedTicket[0].lot.filter(lot => lot.quantity_available > 0) : [];
    return selectedLot.length ? selectedLot[0].quantity_available : 0;
  };

  const getCurrentTicketName = (selectedTicketId: string) => {
    if (!selectedTicketId) return;
    const selectedTicket = eventDetail.tickets.filter(ticket => ticket.id === selectedTicketId);
    return selectedTicket.length ? selectedTicket[0].name : '';
  };

  const onClickAddParticipant = () => {
    if (eventDetail.tickets?.length === 0) {
      Toast.error('Não é possível adicionar participantes em um evento sem ingressos.');
      return;
    }

    const date = eventDetail.end_date.split('/').reverse().join('-');
    const hour = eventDetail.end_hour;

    if (new Date(date + ' ' + hour).getTime() < new Date().getTime()) {
      Toast.error('Não é possível adicionar participantes em um evento que já foi encerrado.');
      return;
    }

    openAddParticipantDialog();
    setFirstTicketAvailableStock(getAvailableStock(eventDetail?.tickets?.[0]?.id));
  };

  const openAddParticipantDialog = () => setIsOpenAddParticipantDialog(true);
  const closeAddParticipantDialog = () => setIsOpenAddParticipantDialog(false);

  const handleOpenModalExportPDF = () => setIsOpenExportPdf(!isOpenExportPdf);

  const handleDownloadFile = (data: string, filename: string) => {
    const a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + data;
    a.target = '_blank';
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleExportXLS = () => {
    attendanceListService
      .exportXLS({
        event_id: filter.event_id,
        list_type: 'list'
      })
      .subscribe(
        response => {
          handleDownloadFile(response, 'lista_de_presenca.xls');
          Toast.show('A lista foi baixada com sucesso!');
        },
        ({ data: err }) => {
          if (err.message === 'Você não possui permissão para realizar esta ação') {
            Toast.error(err.message, 6000);
          }
        }
      );
  };

  const handleExportPDF = (letterByPage = 'true') => {
    setExportLoading(true);
    let exportLetter = true;
    if (letterByPage === 'false') {
      exportLetter = false;
    }
    attendanceListService
      .exportPDF({
        ...filter,
        list_type: 'list',
        letter_by_page: exportLetter
      })
      .subscribe({
        next: response => {
          handleDownloadFile(response, 'lista_de_presenca.pdf');
          setExportLoading(false);
          handleOpenModalExportPDF();
          Toast.show('A lista foi baixada com sucesso!');
        },
        error: ({ data: err }) => {
          if (err.message === 'Você não possui permissão para realizar esta ação') {
            Toast.error(err.message);
          } else {
            Toast.error('Ocorreu um erro ao baixar a lista de presença', 6000);
          }
          setExportLoading(false);
        }
      });
  };

  const handleOpenEditMarker = () => {
    sendEventToGA({
      category: 'Nova Lista de Participantes',
      action: 'Botão',
      label: 'Marcadores'
    });
    setIsOpenEditMarker(!isOpenEditMarker);
  };

  const onCompleteEditMarker = (data: ITags) => {
    onCompleteMarkerEditDialog(data, data.id_temp ? 'add' : 'edit');
    loadTags();
  };

  const onCompleteRemoveMarker = (data: ITags) => {
    onCompleteMarkerEditDialog(data, 'remove');
    loadTags();
  };

  const onCompleteMarkerEditDialog = (data: ITags, action: string) => {
    let newTags = [] as any;
    if (action === 'edit') {
      newTags = [...tags].map(item => {
        if (action === 'edit' && item.id === data.id) {
          item.title = data.title;
          item.color = data.color;
        }
        return item;
      });
    }

    if (action === 'remove') {
      newTags = [...tags].filter(item => item.id !== data.id);
    }

    if (action === 'add') {
      newTags = [...tags].map(item => {
        if (action === 'add' && item.id === data.id_temp) {
          item.title = data.title;
          item.color = data.color;
          item.id = data.id;
        }
        return item;
      });
    }

    handleUpdateTags({
      tags: newTags,
      data: [...attendanceList].map(item => {
        let attendanceTags: ITags[] = newTags;
        const index = attendanceTags.findIndex(tag => tag.id === data.id);

        if (index >= 0) {
          attendanceTags[index] = {
            ...data
          };
          if (action === 'remove') {
            attendanceTags = attendanceTags.filter(item => item.id !== data.id);
          }

          item.attendance_tags = JSON.stringify(attendanceTags);
        }
        return item;
      })
    });
  };

  const onComplete = () => loadTags();

  return (
    <>
      <div
        style={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <PageTitle
          title='Lista de Participantes'
          subtitle='Gerencie todos os participantes deste evento'
          onIconClick={handleOpenAttendanceListTour}
        />
        <Grid
          item
          xs={12}
          md={5}
          style={{
            display: 'flex',
            gap: '16px',
            height: 'fit-content',
            justifyContent: 'flex-end'
          }}
        >
          <Menu onClose={() => setIsOpenExportMenu(false)} open={isOpenExportMenu} anchorEl={buttonExportRef.current}>
            <MenuItem
              onClick={() => {
                handleOpenModalExportPDF();
                sendEventToGA({
                  category: 'Nova Lista de Participantes',
                  action: 'Botão',
                  label: 'Exportar PDF'
                });
                setIsOpenExportMenu(false);
              }}
            >
              <FilePdfOutlined /> &nbsp; PDF
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleExportXLS();
                sendEventToGA({
                  category: 'Nova Lista de Participantes',
                  action: 'Botão',
                  label: 'Exportar XLS'
                });
                setIsOpenExportMenu(false);
              }}
            >
              <FileExcelOutlined /> &nbsp; XLS
            </MenuItem>
          </Menu>
          <Button
            onClick={() => setIsOpenExportMenu(true)}
            id='open-export-menu'
            variant='outlined'
            ref={buttonExportRef}
            endIcon={<DownloadOutlined />}
          >
            <Typography variant='subtitle1'>Exportar</Typography>
          </Button>
          <Button
            onClick={handleOpenEditMarker}
            variant='outlined'
            endIcon={<PinIcon color='#000000' />}
            id='edit-markers-btn'
          >
            <Typography variant='subtitle1'>Marcadores</Typography>
          </Button>
          {eventDetail?.tickets?.[0]?.id && (
            <Button
              onClick={onClickAddParticipant}
              variant='contained'
              color='primary'
              endIcon={<AddIcon />}
              id='add-participant-btn'
            >
              <Typography variant='subtitle1' color='secondary' style={{ color: 'black' }}>
                Adicionar
              </Typography>
            </Button>
          )}
        </Grid>
      </div>

      <EventDetailContext.Provider
        value={{
          eventDetail,
          isOpen: isOpenAddParticipantDialog,
          openAddParticipantDialogTest: openAddParticipantDialog,
          onClose: closeAddParticipantDialog,
          getCurrentTicketName,
          firstTicketAvailableStock,
          getAvailableStock,
          addedParticipant
        }}
      >
        <AddMethodDialog />
      </EventDetailContext.Provider>

      {isOpenExportPdf && (
        <ExportPdfDialog
          key='exportPdfDialog'
          opened={isOpenExportPdf}
          onComplete={handleExportPDF}
          onCancel={handleOpenModalExportPDF}
          loading={exportLoading}
        />
      )}

      {isOpenEditMarker && (
        <MarkerEditDialog
          onCompleteEdit={onCompleteEditMarker}
          onComplete={onComplete}
          onMarkerRemoved={onCompleteRemoveMarker}
          markers={tags}
          opened={isOpenEditMarker}
          onCancel={handleOpenEditMarker}
        />
      )}
    </>
  );
};

export default withStyles(styles)(HeaderList);
